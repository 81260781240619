import { Trans } from '@lingui/macro';
import moment from 'moment';
import React, { useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DeliveryStatusComponent from '../../components/DeliveryStatusComponent';
import { Series } from '../../declarations';
import './styles.scss';

interface MobileSubscriptionProps {
  purshasedSeriesNumber: any;
  allSeries: Series[];
  language: string;
}

const MobileSubscription: React.FC<MobileSubscriptionProps> = props => {
  const { purshasedSeriesNumber, allSeries, language } = props;
  const [currentItem, setCurrentItem] = useState({ index: 0, open: true });

  return (
    <Accordion className="series-table-container" defaultActiveKey="0">
      {purshasedSeriesNumber !== 0 ? (
        allSeries.map((seriesItem: any, index: any) => {
          return (
            <Card key={index}>
              <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                <div className="big-line" />
                <div className="series-row">
                  <div>
                    {currentItem.index === index && currentItem.open ? (
                      <FaCaretDown
                        className="down-icon"
                        onClick={() => setCurrentItem({ index, open: false })}
                        aria-expanded={currentItem.open}
                      />
                    ) : (
                      <FaCaretRight
                        className="down-icon"
                        onClick={() => setCurrentItem({ index, open: true })}
                        aria-expanded={currentItem.open}
                      />
                    )}
                  </div>
                  <div className="series-row-right">
                    <div className="series-subscription-metadata">
                      <div className="series-text-title bold ">
                        {seriesItem.title[language] || seriesItem.title.default}
                      </div>
                    </div>
                    <div className="series-subscription-metadata">
                      <div className="series-metadata-coll">
                        {seriesItem.issues.length} <Trans>Issues</Trans>
                      </div>
                      <div className="series-metadata-coll">${seriesItem.price}</div>
                    </div>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse
                in={currentItem.index === index && currentItem.open}
                eventKey={index.toString()}
              >
                <Card.Body>
                  {seriesItem.issues &&
                    seriesItem.issues.map((issueItem: any, index: any) => {
                      const lenghtActiveIssues =
                        seriesItem &&
                        seriesItem.issues &&
                        seriesItem.issues.filter((issue: any) => issue.status !== 'NOT_AVAILABLE');
                      return (
                        <div key={issueItem.id}>
                          {issueItem.status !== 'NOT_AVAILABLE' && (
                            <>
                              {index === 0 && (
                                <div>
                                  <div className="line" />
                                  <div className="series-subscription-text-container">
                                    <div className="series-data-coll">
                                      <div className="series-text-light">
                                        <Trans>Order No.</Trans>
                                      </div>
                                      <div className="series-text">{seriesItem.id}</div>
                                    </div>
                                    <div className="series-data-coll">
                                      <div className="series-text-light">
                                        <Trans>Date subscribed</Trans>
                                      </div>
                                      <div className="series-text">
                                        {moment(seriesItem.purchasedAt).format('MM/DD/YY')}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="line" />
                                </div>
                              )}
                              <div className="series-subscription-text-container">
                                <div className="series-text">
                                  <Trans>Issue {issueItem.number} (x 1)</Trans>
                                </div>
                                <div className="series-text">
                                  <Trans>USD $</Trans>
                                  {seriesItem.issues &&
                                    (seriesItem.price / seriesItem.issues.length).toFixed(2)}
                                </div>
                              </div>
                              <div className="series-data-block">
                                <div className="series-image-block">
                                  <img
                                    src={issueItem.coverUrl}
                                    alt="issue-img"
                                    className="series-image"
                                  />
                                </div>
                                <div className="series-statuses-block">
                                  <DeliveryStatusComponent issue={issueItem} />
                                </div>
                              </div>

                              {issueItem.status === 'AVAILABLE' && (
                                <div className="personalisate-button-container">
                                  <Button
                                    as={Link}
                                    to={
                                      issueItem.orderId
                                        ? `/review-order/${seriesItem.id}/${issueItem.orderId}`
                                        : `/personalize/${issueItem.id}`
                                    }
                                    className="custom-button primary issue-personalize"
                                  >
                                    <Trans>Personalize</Trans>
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                          {seriesItem.issues.length > 1 &&
                            issueItem.status !== 'NOT_AVAILABLE' &&
                            index < seriesItem.issues.length - 1 && <div className="line" />}

                          {issueItem.status === 'NOT_AVAILABLE' && (
                            <div>
                              <div className="series-subscription-metadata series-subscription-row">
                                <div className="series-text">
                                  <Trans>Issue {issueItem.number}</Trans>
                                </div>
                                <div>
                                  {lenghtActiveIssues && index === lenghtActiveIssues.length ? (
                                    <span className="italic-text">
                                      <Trans>Not available yet… soon!</Trans>
                                    </span>
                                  ) : (
                                    <span className="italic-text">
                                      <Trans>Not available yet…</Trans>
                                    </span>
                                  )}
                                </div>
                              </div>
                              {index !== seriesItem.issues.length - 1 && <div className="line" />}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })
      ) : (
        <div className="empty">
          <img className="empty_image" src={require('../../images/empty.png')} alt="user-logo" />
          <p className="empty_title">
            <Trans>Such emptiness…</Trans>
          </p>
          <p className="empty_description">
            <Trans>You have no active subscriptions</Trans>
          </p>
        </div>
      )}
    </Accordion>
  );
};

export default MobileSubscription;
