import { t, Trans } from '@lingui/macro';
import React, { useCallback, useContext, useState } from 'react';
import { useLoads } from 'react-loads';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { I18nLanguageContext } from '../../components/I18nLoader';
import InfoRectangles from '../../components/InfoRectangles';
import OrderData from '../../components/OrderData';
import OrderSummary from '../../components/OrderSummary';
import { Order, Series } from '../../declarations';
import { ReactComponent as SideArrow } from '../../images/side-arrow.svg';
import MyStoreCheckout from './MyStoreCheckout';

import feathersApp from '../../utils/feathers';

import './styles.scss';
export interface PaymentPageProps {
  seriesId: string;
  orderId: string;
}
const PaymentPage: React.FC<RouteComponentProps<PaymentPageProps>> = props => {
  const { seriesId, orderId } = props.match.params;
  const [order, setOrder] = useState<Order>();
  const [series, setSeries] = useState<Series>();
  const { language, i18n } = useContext(I18nLanguageContext);

  const getSeries = useCallback(async () => {
    try {
      const seriesData = await feathersApp.service('series').get(parseInt(seriesId, 10));
      setSeries(seriesData);
    } catch (e) {
      NotificationManager.error(e.message, i18n._(t`Error`));
    }
  }, [seriesId, i18n]);

  const getOrderInfo = useCallback(async () => {
    try {
      const orderInfo = await feathersApp.service('orders').get(parseInt(orderId, 10), {
        query: {
          includeShipment: true,
        },
      });
      setOrder(orderInfo);
    } catch (e) {
      NotificationManager.error(e.message, i18n._(t`Error`));
    }
  }, [orderId, i18n]);

  useLoads(getOrderInfo, {}, [orderId]);
  useLoads(getSeries, {}, [seriesId]);

  return (
    <div className="payment-container">
      <div className="payment_form">
        <div className="steps-bar">
          <span className="step ">
            <Trans>Shipping</Trans>
          </span>
          <SideArrow className="side-arrow" />
          <span className="step active">
            <Trans>Review & Pay</Trans>
          </span>
        </div>
        <div className="row-container">
          <div className="pay-form">
            <div className="order-data-container">
              {order && <OrderData order={order} seriesId={seriesId} />}
            </div>
            <StripeProvider apiKey="pk_test_9DXQIwAo4A0fhsHMIdcSyU4t00GWt5ZOuS">
              <MyStoreCheckout orderId={orderId} seriesId={seriesId} />
            </StripeProvider>
          </div>
          <div className="order-summary-container">
            <OrderSummary
              title={series && (series.title[language] || series.title.default)}
              issuesNumber={(series && series.issuesNumber) || 0}
              currentNumber={0}
              price={(series && series.price) || 0}
              shippingPrice={3.99}
            />
          </div>
        </div>
      </div>
      <InfoRectangles />
    </div>
  );
};

export default PaymentPage;
