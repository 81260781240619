import { Trans } from '@lingui/macro';
import React, { useCallback, useContext } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useLoads } from 'react-loads';
import { Link, useHistory } from 'react-router-dom';
import { UserDataContext } from '../../../App';
import { User } from '../../../declarations';
import { ReactComponent as SubscriptionIcon } from '../../../images/subscriptions-mobile.svg';
import { ReactComponent as AccountIcon } from '../../../images/user-mobile.svg';
import { userService } from '../../../services/UserService';
import feathersApp from '../../../utils/feathers';
import './styles.scss';

const Avatar: React.FC<{ userData: User; headerAvatar: boolean }> = props => {
  const { userData, headerAvatar } = props;
  const content =
    userData && userData.firstName[0].toUpperCase() + userData.lastName[0].toUpperCase();

  return (
    <div className={`user-dropdown_avatar ${headerAvatar ? 'user-dropdown_header-avatar' : ''}`}>
      {content}
    </div>
  );
};

const UserDropdown: React.FC = props => {
  const history = useHistory();
  const userId = userService.userId;
  const { userData, setUserData } = useContext(UserDataContext);
  const fetchUser = useCallback(async () => {
    if (!userId) {
      return;
    }
    const data = await feathersApp.service('users').get(parseInt(userId, 10));
    setUserData(data);
  }, [userId, setUserData]);

  useLoads(fetchUser, {}, [userService.userId]);

  const logout = useCallback(() => {
    userService.logout();
    history.push('/');
  }, [history]);

  return (
    <NavDropdown
      className="user-dropdown"
      drop="down"
      title={<Avatar userData={userData} headerAvatar={true} />}
      id="basic-nav-dropdown"
    >
      <NavDropdown.Item className="user-dropdown_header" as={Link} to="/account/profile">
        <Avatar userData={userData} headerAvatar={false} />
        <div className="user-dropdown_header_info">
          <span className="user-dropdown_header_info_name">{userData && userData.firstName}</span>
          <span className="user-dropdown_header_info_email">{userData && userData.email}</span>
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item className="user-dropdown_item" as={Link} to="/account/subscription">
        <SubscriptionIcon className="user-dropdown_subscription-icon" />
        <Trans>Subscriptions</Trans>
      </NavDropdown.Item>
      <NavDropdown.Item className="user-dropdown_item" as={Link} to="/account/profile">
        <AccountIcon className="user-dropdown_account-icon" />
        <Trans>Account</Trans>
      </NavDropdown.Item>
      <NavDropdown.Item className="user-dropdown_logout user-dropdown_item" onClick={logout}>
        <Trans>Sign out</Trans>
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserDropdown;
