import { Trans } from "@lingui/macro";
import React from 'react';
import { Link } from "react-router-dom";
import './styles.scss';

const InfoRectangles: React.FC<{}> = props => {
  return (
      <div className="rectangles-wrap">
        <div className="info-rectangle">
          <span><Trans>Delivery</Trans></span>
          <p><Trans>Each issue is prited especially for you and can take 1-3 days to print,
            delivery time is added to this but you should get your comic between 3-7 days.</Trans></p>
        </div>
        <div className="info-rectangle">
          <span><Trans>Returns</Trans></span>
          <p><Trans>Our books are wonderfully personalized so we can't always offer refunds or exchanges.
            However you'll have 12-24 hours after ordering to review & make edits.</Trans></p>
        </div>
        <div className="info-rectangle">
          <span><Trans>Support</Trans></span>
          <p><Trans>You can search our  <Link to="/" className="help-link">FAQs</Link>
            but if you can’t find the answer you are looking for,
            contact us on <Link to="/" className="help-link">8234628356</Link>
            and we will be happy to help.</Trans></p>
        </div>
      </div>
  )
};

export default InfoRectangles;