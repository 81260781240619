// @ts-ignore
import FlippingPages from 'flipping-pages';

// tslint:disable-next-line:no-submodule-imports
import 'flipping-pages/FlippingPages.css';
import React, { useCallback, useRef, useState, useContext, useMemo } from 'react';
import ImagePagination from '../../components/ImagePagination';
import { ReactComponent as Arrow } from '../../images/prevArrow.svg';
import { ScreenWidthContext } from '../../App';
import Loading from '../Loading';
import Page from './Page';
import './styles.scss';

interface ImageCaruselProps {
  images: string[];
  loading: boolean;
  assets: any[];
  assetName: any;
}

const ImageCarusel: React.FC<ImageCaruselProps> = props => {
  const { images, loading, assets, assetName } = props;
  const [activePage, setActivePage] = useState(0);
  const [disabled, setDisable] = useState<boolean>(false);
  const [page1, setPage1] = useState(0);
  const [page2, setPage2] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const animationDuration = 800;
  const { screenWidth } = useContext(ScreenWidthContext);
  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const isTablet = useMemo(() => screenWidth >= 426 && screenWidth <= 1024, [screenWidth]);

  const handlePagination = useCallback(
    (x: number) => {
      const wrapper: HTMLElement | null = wrapperRef.current;
      if (wrapper && x !== activePage) {
        if (x === 0) {
          setPage1(x);
          setPage2(x);
        } else {
          setPage1(x * 2 - 1);
          setPage2(x * 2);
        }
        wrapper.classList.add('opened');
        const toTheEnd = x > activePage;
        if (!toTheEnd) {
          wrapper.classList.remove('ended');
        }
        let tick = toTheEnd ? activePage + 1 : activePage - 1;
        setActivePage(tick);
        toTheEnd ? tick++ : tick--;
        if (Math.abs(x - activePage) > 1) {
          setDisable(true);
          const fullAnimation = setInterval(() => {
            setActivePage(tick);
            toTheEnd ? tick++ : tick--;
          }, animationDuration);
          setTimeout(() => {
            if (wrapper && activePage === 0 && activePage >= images.length / 2) {
              wrapper.classList.remove('opened');
            }
            clearInterval(fullAnimation);
            setDisable(false);
          }, animationDuration * Math.abs(x - activePage) - 1);
        }
      }
    },
    [activePage, images],
  );

  const toggleDisableButtons = useCallback(() => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, animationDuration);
  }, []);

  const isEven = images.length % 2 === 0;
  const halfImages = new Array(Math.ceil((images.length - 1) / 2) + 1).fill(Math.random());

  const previousPages = useCallback(() => {
    if (page1 > 0) {
      toggleDisableButtons();
      handlePagination(activePage - 1);
      if (page1 === 1) {
        setPage1(page1 - 1);
        setPage2(page2 - 2);
      } else {
        setPage1(page1 - 2);
        setPage2(page2 - 2);
      }
    }
  }, [page1, page2, activePage, handlePagination, toggleDisableButtons]);

  const nextPage = useCallback(() => {
    if (page2 < images.length - 1) {
      toggleDisableButtons();
      handlePagination(activePage + 1);
      if (page1 === 0) {
        setPage1(page1 + 1);
      } else {
        setPage1(page1 + 2);
        setPage2(page2 + 2);
      }
    }
  }, [page1, page2, images, activePage, handlePagination, toggleDisableButtons]);

  const clickPage = useCallback(
    (side: string) => {
      if (!disabled) {
        if (side === 'next') {
          nextPage();
        }
        if (side === 'previous') {
          previousPages();
        }
      }
    },
    [disabled, nextPage, previousPages],
  );
  return loading ? (
    <Loading />
  ) : (
    <div className="image-preview">
      <div className="image-preview__body">
        {!isMobile && !isTablet && (
          <div
            className={`image-preview__arrow-wrap ${disabled ? 'disabled' : ''}`}
            onClick={() => clickPage('previous')}
          >
            <Arrow className="image-preview__arrow image-preview__arrow" />
          </div>
        )}
        <div
          className={`flipping-pages-wrapper ${
            activePage === 0 ? '' : activePage >= images.length / 2 ? 'ended' : 'opened'
          }`}
          ref={wrapperRef}
        >
          <FlippingPages
            className="App-pages"
            direction="horizontal"
            selected={activePage}
            onSelectedChange={handlePagination}
            animationDuration={animationDuration}
            swipeLength={230}
            onOverSwipe={() => false}
            onSwipeStart={() => activePage !== 0 && activePage < images.length / 2 && !disabled}
          >
            {halfImages.map((item, index) =>
              index === 0 ? (
                <div key={item} className="carusel-container-two">
                  <Page
                    src={images[index]}
                    isEmpty={true}
                    clickPage={() => clickPage('next')}
                    assetName={assetName}
                    assetsPage={assets[index] || []}
                  />
                </div>
              ) : index === halfImages.length - 1 && isEven ? (
                <div key={item} className="carusel-container-two">
                  <Page
                    clickPage={() => clickPage('previous')}
                    isEmpty={false}
                    src={images[2 * index - 1]}
                    assetName={assetName}
                    assetsPage={assets[2 * index - 1] || []}
                  />
                </div>
              ) : (
                <div key={item} className="carusel-container-two">
                  <Page
                    clickPage={() => clickPage('previous')}
                    isEmpty={false}
                    src={images[2 * index - 1]}
                    assetName={assetName}
                    assetsPage={assets[2 * index - 1] || []}
                  />

                  <Page
                    clickPage={() => clickPage('next')}
                    isEmpty={false}
                    src={images[2 * index]}
                    assetName={assetName}
                    assetsPage={assets[2 * index] || []}
                  />
                </div>
              ),
            )}
          </FlippingPages>
        </div>
        {!isMobile && !isTablet && (
          <div
            className={`image-preview__arrow-wrap ${disabled ? 'disabled' : ''}`}
            onClick={() => clickPage('next')}
          >
            <Arrow className="image-preview__arrow_next" />
          </div>
        )}
      </div>
      <div>
        <ImagePagination
          itemsNumber={Math.ceil((images.length - 1) / 2) + 1}
          activeItem={activePage}
          onClick={handlePagination}
        />
      </div>
    </div>
  );
};

export default ImageCarusel;
