import React from 'react';
import ImageCarusel from '../../../components/ImageCarusel';
import './styles.scss';

interface IssuePreviewPropsType {
  seriesTitle?: string;
  issueTitle?: string;
  images: string[];
  loading: boolean;
  assets: any[];
  assetName: any;
}

const IssuePreview: React.FC<IssuePreviewPropsType> = props => {
  const { seriesTitle, issueTitle, images, loading, assets, assetName } = props;
  return (
    <div className="personalize_preview">
      <p className="personalize_preview_title">
        <span className="personalize_preview_title_series">{seriesTitle}</span>
        <span className="personalize_preview_title_issue">{issueTitle}</span>
      </p>
      <div className="personalize_preview_page">
        <ImageCarusel
          images={images}
          loading={loading}
          assets={assets || []}
          assetName={assetName}
        />
      </div>
    </div>
  );
};

export default IssuePreview;
