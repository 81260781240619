import React, { useEffect, useRef, useMemo, useState, useContext } from 'react';
import { ButtonToolbar, Overlay, Popover } from 'react-bootstrap';
import { ReactComponent as SelectionIcon } from '../../images/selection.svg';
import { ScreenWidthContext } from '../../App';
import MobileImageSelect from './MobileImageSelect';
import './styles.scss';

const ImageSelect: React.FC = (props: any) => {
  const { options, field, form } = props;
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<any>(null);
  const ref = useRef<any>(null);
  const { screenWidth } = useContext(ScreenWidthContext);

  useEffect(() => {
    if (!field.value) {
      form.setFieldValue(field.name, options[0]);
    }
  }, [options, field.value, form.setFieldValue, field.name, form]);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };
  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);

  return (
    <>
      {isMobile ? (
        <MobileImageSelect options={options} form={form} field={field} />
      ) : (
        <ButtonToolbar ref={ref}>
          <div onClick={handleClick} className="image-select_container ">
            {
              <img
                className="image-select_image"
                alt="option"
                src={field.value && field.value.label}
              />
            }
          </div>

          <Overlay
            show={show}
            target={target}
            placement="top"
            container={ref.current}
            rootClose={true}
            onHide={() => setShow(!show)}
          >
            <Popover id="popover-contained">
              <Popover.Content>
                {options.map((option: any) => {
                  return (
                    <div className="image-select_image-container" key={option.value}>
                      {option.value === (field.value && field.value.value) && (
                        <SelectionIcon className="checked-icon" />
                      )}
                      <img
                        className={`image-select_image  ${
                          option.value === (field.value && field.value.value)
                            ? ' image-select_image--active'
                            : 'image-select_image--inactive'
                        }`}
                        alt="option"
                        onClick={o => {
                          form.setFieldValue(field.name, option);
                          setShow(!show);
                        }}
                        src={option.label}
                        {...field}
                      />
                    </div>
                  );
                })}
              </Popover.Content>
            </Popover>
          </Overlay>
        </ButtonToolbar>
      )}
    </>
  );
};

export default ImageSelect;
