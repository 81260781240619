// tslint:disable-next-line: no-submodule-imports
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
// @ts-ignore
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './App.scss';
import I18nLoader from './components/I18nLoader';
import { User } from './declarations';
import AppRouter from './Routing/AppRouter';

export const ScreenWidthContext = React.createContext<{ screenWidth: any }>({ screenWidth: 0 });

export const UserDataContext = React.createContext<{ userData: any; setUserData: any }>({
  userData: {},
  setUserData: () => {},
});

const App: React.FC = () => {
  const [userData, setUserData] = useState<User>();
  const [width, setWidth] = useState<any>(window.innerWidth);
  const value = useMemo(
    () => ({
      userData,
      setUserData,
    }),
    [userData, setUserData],
  );

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });
  return (
    <I18nLoader
      handleImport={useCallback(
        (language: string): Promise<any> => import(`./locales/${language}/messages.js`),
        [],
      )}
    >
      <UserDataContext.Provider value={value}>
        <ScreenWidthContext.Provider value={{ screenWidth: width }}>
          <AppRouter />
          <NotificationContainer />
        </ScreenWidthContext.Provider>
      </UserDataContext.Provider>
    </I18nLoader>
  );
};

export default App;

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    env: { apiUrl: string };
  }
}
