import { t, Trans } from '@lingui/macro';
import { Field, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLoads } from 'react-loads';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { ScreenWidthContext } from '../../../App';
import { I18nLanguageContext } from '../../../components/I18nLoader';
import InfoRectangles from '../../../components/InfoRectangles';
import Loading from '../../../components/Loading';
import SelectField from '../../../components/SelectField';
import { Issue, OrderAddress } from '../../../declarations';
import { ReactComponent as SideArrow } from '../../../images/side-arrow.svg';
import { userService } from '../../../services/UserService';
import feathersApp from '../../../utils/feathers';
import { orderSchema } from '../../../validationSchemas';
import { countryOptions } from '../../Profile/options';
import '../styles.scss';

interface ShippingEditRouteParams {
  seriesId: string;
  orderId: string;
}

const ShippingEdit: React.FC<RouteComponentProps<ShippingEditRouteParams>> = props => {
  const history = useHistory();
  const { seriesId, orderId } = props.match.params;
  const [issue, setIssue] = useState<Issue>();
  const [orderShippingId, setOrderShippingId] = useState(0);
  const [checkMarkClassName, setCheckMarkClassName] = useState<string>('checkmark');
  const changeCheckmark = () =>
    setCheckMarkClassName(
      checkMarkClassName.indexOf('shown') !== -1 ? 'checkmark' : 'checkmark shown',
    );
  const { i18n } = useContext(I18nLanguageContext);
  const deliveryDate = moment()
      .add(7, 'days')
      .format('D MMM YYYY');

  const [userProfile, setUserProfile] = useState<OrderAddress>({
    firstName: '',
    lastName: '',
    email: '',
    shippingAddress: {
      address1: '',
      address2: '',
      state: '',
      country: '',
      postcode: '',
      town: '',
    },
  });

  const getOrderInfo = async () => {
    try {
      const order = await feathersApp.service('orders').find({
        query: {
          id: orderId,
          includeShipment: true,
          includeIssues: true,
        },
      });
      const data = order.data[0].order_shipment;
      setIssue(order.data[0].issues[0]);
      setOrderShippingId(order.data[0].order_shipment.id);
      const o = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        shippingAddress: {
          address1: data.address1,
          address2: data.address2,
          state: data.state,
          country: { value: data.country, label: data.country },
          postcode: data.postcode,
          town: data.town,
        },
      };
      setUserProfile(o);
    } catch (e) {
      NotificationManager.error(e.message, i18n._(t`Error`));
    }
  };

  const saveAccountDetails = useCallback(
    async (values: any) => {
      try {
        const userId: any = userService.userId;
        await feathersApp.service('users').patch(userId, {
          ...values,
          shippingAddress: {
            ...values.shippingAddress,
            country: values.shippingAddress.country.value,
          },
        });
        NotificationManager.success(
          i18n._(t`Your changes are successfully applied`),
          i18n._(t`Success`),
        );
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error`));
      }
    },
    [i18n],
  );
  const { isPending } = useLoads(getOrderInfo, {}, []);

  const shippingSubmit = useCallback(
    async (values: any) => {
      try {
        await feathersApp.service('order-shipments').patch(orderShippingId, {
          ...values.shippingAddress,
          firstName: values.firstName,
          lastName: values.lastName,
          country: values.shippingAddress.country.value,
        });

        if (checkMarkClassName.indexOf('shown') !== -1) {
          await saveAccountDetails(values);
        }
        history.push(
          `/${issue && issue.number === 1 ? 'payment' : 'review-order'}/${seriesId}/${orderId}`,
        );
        NotificationManager.success(
          i18n._(t`Shipping address has been successfully changed!`),
          i18n._(t`Success!`),
        );
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error!`));
      }
    },
    [
      saveAccountDetails,
      checkMarkClassName,
      history,
      orderShippingId,
      seriesId,
      issue,
      orderId,
      i18n,
    ],
  );
  const countryOptionsResult = countryOptions.map((countryOption: any) => ({
    value: countryOption.name,
    label: countryOption.name,
  }));

  return isPending ? (
    <Loading />
  ) : (
    <Formik
      validationSchema={orderSchema(i18n)}
      initialValues={userProfile}
      enableReinitialize
      onSubmit={shippingSubmit}
    >
      {({ handleSubmit, handleChange, values, errors, touched, handleBlur, isValid }) => {
        return (
          <div className="shipping-container">
            <div className="steps-bar">
              <span className="step active">
                <Trans>Shipping</Trans>
              </span>
              <SideArrow className="side-arrow" />
              <span className="step">
                <Trans>Review & Pay</Trans>
              </span>
            </div>
            <div className="row-container">
              <Form className="shipping-form" noValidate onSubmit={handleSubmit}>
                <Form.Row className="shipping-title">
                  <Form.Group as={Col}>
                    <Trans>Delivery Details</Trans>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridFirstName" className="half-row">
                    <Form.Label>
                      <Trans>First Name</Trans>
                    </Form.Label>
                    <Form.Control
                      type="string"
                      name="firstName"
                      placeholder="First name"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.firstName && errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridLastName">
                    <Form.Label>
                      <Trans>Surname</Trans>
                    </Form.Label>
                    <Form.Control
                      type="string"
                      name="lastName"
                      placeholder="Surname"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.lastName && errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridStreetAddress">
                    <Form.Label>
                      <Trans>Street Address</Trans>
                    </Form.Label>
                    <Form.Control
                      type="string"
                      name="shippingAddress.address1"
                      placeholder="Street Address"
                      value={values.shippingAddress.address1}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.shippingAddress && errors.shippingAddress.address1)}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.shippingAddress &&
                        touched.shippingAddress.address1 &&
                        errors.shippingAddress &&
                        errors.shippingAddress.address1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridAddress">
                    <Form.Label>
                      <Trans>Address (Optional)</Trans>
                    </Form.Label>
                    <Form.Control
                      type="string"
                      name="shippingAddress.address2"
                      placeholder="Address (Optional)"
                      value={values.shippingAddress.address2}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.shippingAddress && errors.shippingAddress.address2)}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.shippingAddress &&
                        touched.shippingAddress.address2 &&
                        errors.shippingAddress &&
                        errors.shippingAddress.address2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridCity" className="half-row">
                    <Form.Label>
                      <Trans>Town/City</Trans>
                    </Form.Label>
                    <Form.Control
                      type="string"
                      name="shippingAddress.town"
                      placeholder="Town/City"
                      value={values.shippingAddress.town}
                      onChange={handleChange}
                      isInvalid={!!(errors.shippingAddress && errors.shippingAddress.town)}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.shippingAddress &&
                        touched.shippingAddress.town &&
                        errors.shippingAddress &&
                        errors.shippingAddress.town}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridPostcode">
                    <Form.Label>
                      <Trans>Postcode</Trans>
                    </Form.Label>
                    <Form.Control
                      type="string"
                      name="shippingAddress.postcode"
                      placeholder="Postcode"
                      value={values.shippingAddress.postcode}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.shippingAddress && errors.shippingAddress.postcode)}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.shippingAddress &&
                        touched.shippingAddress.postcode &&
                        errors.shippingAddress &&
                        errors.shippingAddress.postcode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridCountry" className="half-row">
                    <Form.Label>
                      <Trans>Country</Trans>
                    </Form.Label>
                    <Field
                      name="shippingAddress.country"
                      placeholder="Country"
                      options={countryOptionsResult}
                      component={SelectField}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>
                      <Trans>State</Trans>
                    </Form.Label>
                    <Form.Control
                      type="string"
                      name="shippingAddress.state"
                      placeholder="State"
                      value={values.shippingAddress.state}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.shippingAddress && errors.shippingAddress.state)}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.shippingAddress &&
                        touched.shippingAddress.state &&
                        errors.shippingAddress &&
                        errors.shippingAddress.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridEmail" className="email">
                    <Form.Label>
                      <Trans>Email</Trans>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched.email && errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <small className="email-info">
                  <Trans>We'll update you on your order status</Trans>
                </small>
                <Form.Row className="save-address-checkbox">
                  <Form.Group as={Col} controlId="formGridCheckbox">
                    <img
                      className={checkMarkClassName}
                      src={require('../../../images/checkmark.png')}
                      alt="checkmark"
                    />
                    <Form.Check type="checkbox" label="" onClick={changeCheckmark} />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="shipping-title">
                  <Form.Group as={Col}>
                    <Trans>Shipping</Trans>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="delivery-wrap">
                  <div className="delivery">
                    <Row>
                      <span>
                        <Trans>Standard</Trans>
                      </span>
                      <span>$3.99</span>
                    </Row>
                    <Row className="mobile-shipping-date">
                      <span>
                        <Trans>Get it by</Trans>&nbsp;{deliveryDate}
                      </span>
                    </Row>
                    <Row>
                      <p>
                        <Trans>
                          Sent by: Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                          eiusmod tempor incididunt ut labore et dolore magna aliqua.{' '}
                        </Trans>
                      </p>
                    </Row>
                  </div>
                </Form.Row>
                <Button
                  variant="primary"
                  type="submit"
                  className="continue-button primary"
                  disabled={!isValid}
                >
                  <Trans>Continue to</Trans> {issue && issue.number === 1 ? 'Payment' : 'Review'}
                </Button>
                <Row className="terms-row">
                  <p className="terms">
                    <Trans>By clicking “Continue” you agree to our</Trans>{' '}
                    <Link to="/" className="terms-link">
                      <Trans>terms and conditions</Trans>
                    </Link>{' '}
                    <Trans>and</Trans>{' '}
                    <Link to="/" className="terms-link">
                      <Trans>Privacy & Terms.</Trans>
                    </Link>
                  </p>
                </Row>
              </Form>
            </div>
            <InfoRectangles />
          </div>
        );
      }}
    </Formik>
  );
};

export default ShippingEdit;
