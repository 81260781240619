import { Trans } from '@lingui/macro';
import React, { useContext, useMemo } from 'react';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ReactComponent as SubscriptionIcon } from '../../images/subscriptions.svg';
import { ReactComponent as AccountIcon } from '../../images/user.svg';
import { ScreenWidthContext } from '../../App';
import Profile from '../Profile';
import Subscription from '../Subscription';
import './styles.scss';

const Account: React.SFC<RouteComponentProps> = props => {
  const { location } = props;
  const { screenWidth } = useContext(ScreenWidthContext);

  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);

  return (
    <div>
      <div className="red-bottom">
        <div className="content-container">
          <div className="account-title">
            {location.pathname === '/account/profile' && <Trans>Account</Trans>}
            {location.pathname === '/account/subscription' && <Trans>Subscription</Trans>}
            {location.pathname === '/account/payment' && <Trans>Payment</Trans>}
          </div>
        </div>
      </div>
      <div className="account-container">
        {!isMobile && (
          <div className="account-tabs">
            <Link
              className={`account-tab-content${
                location.pathname === '/account/profile' ? ' active' : ' deactive'
              }`}
              to="/account/profile"
            >
              <AccountIcon />
              <div className="account-tab-text">
                <Trans>Account</Trans>
              </div>
            </Link>
            <Link
              className={`account-tab-content${
                location.pathname === '/account/subscription' ? ' active' : ' deactive'
              }`}
              to="/account/subscription"
            >
              <SubscriptionIcon />
              <div className="account-tab-text">
                <Trans>Subscription</Trans>
              </div>
            </Link>
            {/*<Link
            className={`account-tab-content${
              location.pathname === '/account/payment' ? ' active' : ' deactive'
            }`}
            to="/account/payment"
          >
            <PaymentIcon />
            <div className="account-tab-text">
              <Trans>Payment</Trans>
            </div>
          </Link>
          */}
          </div>
        )}
        <div className="content-container">
          <Switch>
            <Route path="/account/profile" component={Profile} />
            <Route path="/account/subscription" component={Subscription} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Account;
