import { t, Trans } from '@lingui/macro';
import { Formik } from 'formik';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { Button, Form } from 'react-bootstrap';
import { useLoads } from 'react-loads';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ScreenWidthContext } from "../../App";
import { I18nLanguageContext } from '../../components/I18nLoader';
import OrderData from '../../components/OrderData';
import OrderSummary from '../../components/OrderSummary';
import { Order, OrderReview, Series } from '../../declarations';
import { ReactComponent as LockIcon } from '../../images/lock.svg';
import feathersApp from '../../utils/feathers';
import './styles.scss';

interface ReviewRouteParams {
  orderId: string;
  seriesId: string;
}

const ReviewOrder: React.FC<RouteComponentProps<ReviewRouteParams>> = props => {
  const history = useHistory();
  const { language, i18n } = useContext(I18nLanguageContext);
  const [series, setSeries] = useState<Series>();
  const [order, setOrder] = useState<Order>();
  const [issue, setIssue] = useState();
  const { seriesId, orderId } = props.match.params;
  const { screenWidth } = useContext(ScreenWidthContext);
  const isTablet = useMemo(() => screenWidth >= 426 && screenWidth <= 1024, [screenWidth]);

  const [shipmentInfo, setShipmentInfo] = useState<OrderReview>({
    email: '',
    shippingAddress: {
      address1: '',
      state: '',
      country: '',
      postcode: '',
      town: '',
    },
  });
  const onBackButtonEvent = useCallback(
    (e: any) => {
      e.preventDefault();
      history.push(`/series/${seriesId}`);
    },
    [history, seriesId],
  );

  useEffect(() => {
    window.onpopstate = onBackButtonEvent;
    return () => {
      window.onpopstate = null;
    };
  }, [onBackButtonEvent]);

  const getOrderInfo = useCallback(async () => {
    try {
      const orderInfo = await feathersApp.service('orders').get(parseInt(orderId, 10), {
        query: {
          includeShipment: true,
          includeIssues: true,
        },
      });
      setOrder(orderInfo);
      setIssue(orderInfo.issues[0]);
      const shippingAddress = orderInfo.order_shipment;
      const o = {
        email: shippingAddress.email,
        shippingAddress,
      };
      setShipmentInfo(o);
    } catch (e) {
      NotificationManager.error(e.message, i18n._(t`Error`));
    }
  }, [orderId, i18n]);

  const getSeries = useCallback(async () => {
    try {
      const seriesData = await feathersApp.service('series').get(parseInt(seriesId, 10));
      setSeries(seriesData);
    } catch (e) {
      NotificationManager.error(e.message, i18n._(t`Error`));
    }
  }, [seriesId, i18n]);

  const confirmOrder = useCallback(async () => {
    try {
      await feathersApp.service('orders').patch(orderId, { status: 'CONFIRMED' });
      NotificationManager.success(
        i18n._(t`Your order has been successfully confirmed.`),
        i18n._(t`Success`),
      );
      history.push(`/confirmation/${seriesId}/${orderId}`);
    } catch (e) {
      NotificationManager.error(e.message, i18n._(t`Error`));
    }
  }, [history, orderId, seriesId, i18n]);

  const { isPending, load } = useLoads(confirmOrder, { defer: true }, []);

  useLoads(getOrderInfo, {}, [orderId]);
  useLoads(getSeries, {}, [seriesId]);

  return (
    <Formik initialValues={shipmentInfo} enableReinitialize onSubmit={load}>
      {({ handleSubmit }) => {
        return (
          <div className="review-container">
            <Form.Row>
              <span className="review-title">
                <Trans>Review Order</Trans>
              </span>
            </Form.Row>
            <div className="row-container">
              <Form className="review-form" noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <div className="order-data-container">
                    {order && <OrderData order={order} seriesId={seriesId} />}
                  </div>
                </Form.Row>
                <Form.Row className="buttons">
                  <Button
                    onClick={() => history.push(`/series/${seriesId}`)}
                    variant="primary"
                    className="buttons__back-button gray"
                  >
                    <Trans>Back</Trans>
                  </Button>
                  {isTablet && (<div className="transactions-are">
                    <LockIcon className="lock-icon" />{' '}
                    <Trans>All transactions are secured with SSL encryption</Trans>
                  </div>)}
                  <Button
                    variant="primary"
                    type="submit"
                    className="buttons__confirm-button custom-button primary"
                    disabled={isPending}
                  >
                    <Trans>Confirm Order</Trans>
                  </Button>
                </Form.Row>
                {!isTablet && (<Form.Row className="secure-info">
                  <LockIcon className="lock-icon" />
                  <p>
                    <Trans>All transactions are secured with SSL encryption</Trans>
                  </p>
                </Form.Row>
                )}
              </Form>
              <div className="order-summary-container">
                <OrderSummary
                  title={series && (series.title[language] || series.title.default)}
                  issuesNumber={0}
                  currentNumber={issue && issue.number}
                  price={0}
                  shippingPrice={0}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ReviewOrder;
