import React, { useMemo } from 'react';
import './styles.scss';

interface ImagePaginationPropsType {
  itemsNumber: number;
  activeItem: number;
  onClick: (item: number) => void;
}

const ImagePagination: React.FC<ImagePaginationPropsType> = (props) => {
  const { itemsNumber, activeItem, onClick } = props;

  const items = useMemo(() => {
    const elements = new Array(itemsNumber).fill(0);
    return elements.map((_, index) => (
      <span
        className={`image-pagination_element ${index === activeItem ? 'image-pagination_element--active': ''}`}
        key={index}
        onClick={() => onClick(index)}
      />
    ));
  }, [itemsNumber, activeItem, onClick]);

  return (
    <div className="image-pagination">
      {items}
    </div>
  );
};

export default ImagePagination;
