import React from 'react';
import './styles.scss';

interface MobileSelectProps {
  options: any;
  form: any;
  field: any;
}

const MobileImageSelect: React.FC<MobileSelectProps> = (props: any) => {
  const { options, form, field } = props;
  return (
    <div className="image-select_mobile-container">
      {options.map((option: any) => (
        <div
          key={option.value}
          className={`image-select_image-container  ${
            option.value === (field.value && field.value.value)
              ? ' image-select_image--active'
              : ' '
          }`}
        >
          <img
            className="image-select_image"
            alt="option"
            onClick={() => {
              form.setFieldValue(field.name, option);
            }}
            src={option.label}
            {...field}
          />
        </div>
      ))}
    </div>
  );
};

export default MobileImageSelect;
