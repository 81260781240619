import { Trans } from '@lingui/macro';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ScreenWidthContext } from '../../App';
import './styles.scss';

interface OrderSummaryPropsType {
  title: string;
  issuesNumber: number;
  price: number;
  shippingPrice: number;
  currentNumber: number;
}

const OrderSummary: React.FC<OrderSummaryPropsType> = props => {
  const { title, issuesNumber, price, shippingPrice, currentNumber } = props;
  const [summaryOpen, setSummaryOpen] = useState(false);
  const { screenWidth } = useContext(ScreenWidthContext);
  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const [isFixed, setIsFixed] = useState(true);
  const orderWrapRef = useRef<HTMLDivElement>(null);
  const heightAboveContainer: number = 154;

  useEffect(() => {
    const orderSummary: HTMLElement | null = orderWrapRef.current;
    const orderSummaryContainerHeight: number | undefined = orderSummary?.parentElement?.clientHeight;
    const orderSummaryHeight: number | undefined = orderSummary?.clientHeight;

    const handleScroll = () => {
      (orderSummaryContainerHeight || 0) -
        (window.scrollY + (orderSummaryHeight || 0) + heightAboveContainer) >
      0
        ? setIsFixed(true)
        : setIsFixed(false);
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [setIsFixed]);

  return (
    <div className={`order-summary-wrap`} ref={orderWrapRef}>
      <Row
        className={`${summaryOpen ? 'opened' : ''}`}
        onClick={() => {
          if (isMobile) {
            setSummaryOpen(!summaryOpen);
          }
        }}
      >
        <p>
          <Trans>Order Summary</Trans>
        </p>
      </Row>
      {(summaryOpen || !isMobile) && (
        <Row>
          <p>
            {title} {issuesNumber ? `(x${issuesNumber})` : `- Issue ${currentNumber}`}
          </p>
          <span>${price.toFixed(2)}</span>
        </Row>
      )}
      {!isMobile && (
        <Row>
          <span>
            <Trans>Subtotal</Trans>
          </span>
          <span>${price.toFixed(2)}</span>
        </Row>
      )}
      {(summaryOpen || !isMobile) && (
        <Row>
          <span>
            <Trans>Shipping</Trans>
          </span>
          <span>${shippingPrice || 0}</span>
        </Row>
      )}
      {(summaryOpen || !isMobile) && (
        <Row>
          <span>
            <Trans>Order Total</Trans>
          </span>
          <span className="total-price">${(price + (shippingPrice || 0)).toFixed(2)}</span>
        </Row>
      )}
    </div>
  );
};

export default OrderSummary;
