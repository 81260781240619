import { t, Trans } from '@lingui/macro';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { useLoads } from 'react-loads';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ScreenWidthContext } from '../../App';
import { I18nLanguageContext } from '../../components/I18nLoader';
import ImagePagination from '../../components/ImagePagination';
import LanguagesComponent from '../../components/LanguagesComponent';
import Loading from '../../components/Loading';
import { Series } from '../../declarations';
import { ReactComponent as Arrow } from '../../images/prevArrow.svg';
import { userService } from '../../services/UserService';
import feathersApp from '../../utils/feathers';
import { ReactComponent as BackIcon } from '../Personalize/back-icon.svg';
import './styles.scss';

interface SeriesDetailsRouteParams {
  seriesId: string;
}

const SeriesDetails: React.FC<SeriesDetailsRouteParams> = props => {
  const params: any = useParams();
  const { seriesId } = params;
  const history = useHistory();
  const { screenWidth } = useContext(ScreenWidthContext);
  const { language, i18n } = useContext(I18nLanguageContext);
  const [series, setSeries] = useState<Series>();
  const [issueId, setIssueId] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [activeImage, setActiveImage] = useState(0);
  const [isImgLoading, setImageLoading] = useState(false);

  const getOrder = useCallback(async firstIssueId => {
    const order = await feathersApp.service('orders').find({
      query: {
        issueId: firstIssueId,
      },
    });
    if (order.data[0] && order.data[0].status === 'CREATED') {
      setOrderId(order.data[0].id);
    }
  }, []);

  const getSeries = useCallback(async () => {
    try {
      const img = new Image();
      setImageLoading(true);
      const data = await feathersApp.service('series').get(parseInt(seriesId, 10), {
        query: {
          includeIssues: true,
          includeLanguages: true,
          includeCustomerStatus: true,
        },
      });
      const firstIssueId = data.issues[0] && data.issues[0].id;
      const { data: preview } = await feathersApp.service('generated-content').find({
        query: {
          issueId: firstIssueId,
          $limit: 1,
        },
      });
      img.onload = () => {
        setImageLoading(false);
      };
      img.src = data.coverUrl;
      if (data && !data.isPurchased && userService.isAuthenticated()) {
        getOrder(firstIssueId);
      }
      if (preview && preview[0]) {
        const images = preview[0].preview.slice(2, 4);
        setPreviewImages(images);
      }
      setSeries(data);
      setIssueId(firstIssueId);
    } catch (e) {
      NotificationManager.error(e.message, i18n._(t`Error`));
    }
  }, [seriesId, getOrder, i18n]);

  const { isPending } = useLoads(getSeries, {}, [seriesId]);
  const seriesTitle = series && (series.title[language] || series.title.default);

  const nextImage = useCallback(() => {
    if (activeImage >= [series && series.coverUrl, ...previewImages].length - 1) {
      setActiveImage([series && series.coverUrl, ...previewImages].length - 1);
    } else {
      setActiveImage(activeImage + 1);
    }
  }, [activeImage, previewImages, series]);

  const previousImage = useCallback(() => {
    if (activeImage <= 0) {
      setActiveImage(0);
    } else {
      setActiveImage(activeImage - 1);
    }
  }, [activeImage]);

  const isMobile = useMemo(() => screenWidth <= 599, [screenWidth]);

  const isTablet = useMemo(() => screenWidth >= 600 && screenWidth <= 1024, [screenWidth]);
  const isLoading = isPending || isImgLoading;

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="series-details-container">
            <div className="red-banner" />
            <div className="series-info">
              {isMobile && (
                <div className="series-header">
                  <Link to="/" className="go-back">
                    <BackIcon className="go-back_icon" />
                    <Trans>Back to store</Trans>
                  </Link>
                  <div
                    className={`series-title ${
                      seriesTitle && seriesTitle.length > 30 ? ' big' : ''
                    }`}
                  >
                    {seriesTitle}
                  </div>
                </div>
              )}
              <div className="series-info_cover-wrapper">
                {!isMobile && !isTablet && (
                  <div
                    className={`series-info__arrow-wrap series-info__arrow-wrap_prev`}
                    onClick={() => previousImage()}
                  >
                    <Arrow className="image-preview__arrow image-preview__arrow" />
                  </div>
                )}
                {[series && series.coverUrl, ...previewImages].map((image, index) => (
                  <div key={index}>
                    <div className="left-side" onClick={() => previousImage()} />
                    <div className="right-side" onClick={() => nextImage()} />
                    <img
                      className={`series-avatar ${
                        index === activeImage ? 'series-avatar--active' : ''
                      }`}
                      src={image}
                      alt="cover"
                    />
                  </div>
                ))}
                {!isMobile && !isTablet && (
                  <div
                    className={`series-info__arrow-wrap series-info__arrow-wrap_next`}
                    onClick={() => nextImage()}
                  >
                    <Arrow className="image-preview__arrow_next" />
                  </div>
                )}
                <ImagePagination
                  itemsNumber={[series && series.coverUrl, ...previewImages].length}
                  activeItem={activeImage}
                  onClick={setActiveImage}
                />
              </div>
              <div className="series-content">
                {!isMobile && (
                  <div className="series-header">
                    <Link to="/" className="go-back">
                      <BackIcon className="go-back_icon" />
                      <Trans>Back to store</Trans>
                    </Link>
                    <div className="series-title">
                      {series && (series.title[language] || series.title.default)}
                    </div>
                  </div>
                )}
                <div className="series-description">
                  {isMobile ? (
                    <div className="price-block">
                      <div className="series-price-container">
                        <div className="series-price">
                          ${series ? (series.price / series.issuesNumber).toFixed(2) : 0}
                        </div>
                        <div className="series-price-title">
                          <Trans>per issue *</Trans>
                        </div>
                      </div>
                      <div className="series-price-description">
                        <br /> <Trans>*Total cost will be </Trans>&nbsp;$
                        {series && series.price.toFixed(2)}&nbsp;
                        <Trans>for</Trans>&nbsp;
                        {series && series.issuesNumber}&nbsp;
                        <Trans>
                          issues.
                          <br /> Shipping not included.
                        </Trans>
                      </div>
                    </div>
                  ) : (
                    <div className="price-block">
                      <div className="series-price">
                        ${series ? (series.price / series.issuesNumber).toFixed(2) : 0}*
                      </div>
                      <div className="series-price-description">
                        <Trans>* Per issue per week.</Trans>
                        <br /> <Trans>Total cost will be </Trans>&nbsp;$
                        {series && series.price.toFixed(2)}&nbsp;
                        <Trans>for</Trans> {series && series.issuesNumber}&nbsp;
                        <Trans>issues. Shipping not included.</Trans>
                      </div>
                    </div>
                  )}
                  {isMobile ? (
                    <div className="series-metadata">
                      <div className="grey-line" />
                      <div className="metadeta-container">
                        <div className="series-metadata-column">
                          <div className="series-metadata-title">
                            <Trans>Issues</Trans>
                          </div>
                          <div className="series-metadata-value">
                            {series && series.issuesNumber} <Trans>(1 weekly)</Trans>
                          </div>
                        </div>
                        <div className="series-metadata-column">
                          <div className="series-metadata-title">
                            <Trans>Languages</Trans>
                          </div>
                          <div className="series-metadata-value">
                            <LanguagesComponent languages={series && series.languages} />
                          </div>
                        </div>
                      </div>
                      <div className="grey-line" />
                    </div>
                  ) : (
                    <>
                      {isTablet && <div className="grey-line" />}
                      <div className="series-metadata">
                        <div className="metadeta-container">
                          <div className="series-metadata-column">
                            <div className="series-metadata-title">
                              <Trans>Issues</Trans>
                            </div>
                            <div className="series-metadata-value">
                              {series && series.issuesNumber}
                            </div>
                          </div>
                          <div className="series-metadata-column">
                            <div className="series-metadata-title">
                              <Trans>Languages</Trans>
                            </div>
                            <div className="series-metadata-value">
                              <LanguagesComponent languages={series && series.languages} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {isTablet && <div className="grey-line" />}
                    </>
                  )}

                  <div className="series-text-description">
                    {(series && series.description[language]) ||
                      (series && series.description.default)}
                  </div>
                  {issueId && series && !series.isPurchased && (
                    <Button
                      className="primary personalize-button"
                      onClick={() =>
                        orderId
                          ? history.push(`/payment/${seriesId}/${orderId}`)
                          : history.push(`/personalize/${issueId}`)
                      }
                    >
                      <Trans>Personalise</Trans>
                    </Button>
                  )}
                  {series && series.isPurchased && (
                    <div className="series-buttons-container">
                      <div className="subscribted-container">
                        <FaCheckCircle className="subscribed-icon" />
                        <Trans>Subscribed</Trans>
                      </div>
                      <Button
                        className="custom-button primary"
                        as={Link}
                        to="/account/subscription"
                      >
                        <Trans>View Subscriptions</Trans>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SeriesDetails;
