import { Trans } from '@lingui/macro';
import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as FacebookIcon } from '../../images/icon-facebook.svg';
import { ReactComponent as TwitterIcon } from '../../images/icon-twi.svg';
import { ReactComponent as Logo } from '../../images/logo.svg';
import {I18nLanguageContext} from "../I18nLoader";
import LanguageSelect from './LanguageSelect';
import './styles.scss';

const Footer: React.FC = () => {
  const { language } = useContext(I18nLanguageContext);
  return (
    <footer className="footer">
      <div className="footer_top" />
      <div className="footer_main">
        <div className="footer_main_logo-wrapper">
          <Logo className="footer_main_logo" />
        </div>
        <nav className='footer_main_links'>
          <div className="footer_main_links_col">
            <h5 className="footer_main_links_col_title"><Trans>Support</Trans></h5>
            <Link to="/" className="footer_main_links_col_element"><Trans>Documents</Trans></Link>
            <Link to="/" className="footer_main_links_col_element"><Trans>FAQs</Trans></Link>
            <Link to="/" className="footer_main_links_col_element"><Trans>Contact Us</Trans></Link>
          </div>
          <div className="footer_main_links_col">
            <h5 className="footer_main_links_col_title"><Trans>Company</Trans></h5>
            <Link to="/" className="footer_main_links_col_element"><Trans>About Us</Trans></Link>
            <Link to="/" className="footer_main_links_col_element"><Trans>Partners</Trans></Link>
          </div>
        </nav>
        <nav className="footer_main_social">
          <a className="footer_main_social_icon" href="https://facebook.com">
            <FacebookIcon className="footer_main_social_icon_facebook"/>
          </a>
          <a className="footer_main_social_icon" href="https://twitter.com">
            <TwitterIcon className="footer_main_social_icon_twitter"/>
          </a>
        </nav>
      </div>
      <div className="footer_bottom">
        <span className="footer_bottom_rights"><Trans>© Marvel. All rights reserved.</Trans></span>
        <nav className={`footer_bottom_links${language !== 'en' ? ' columns' : ''}`}>
          <Link className="footer_bottom_links_item" to="/"><Trans>Terms of Service</Trans></Link>
          <Link className="footer_bottom_links_item" to="/"><Trans>Privacy Policy</Trans></Link>
          <Link className="footer_bottom_links_item" to="/"><Trans>Security</Trans></Link>
          <Link className="footer_bottom_links_item" to="/"><Trans>Sitemap</Trans></Link>
        </nav>
        <div className="footer_bottom_language-picker"><LanguageSelect /></div>
      </div>
    </footer>
  );
};

export default Footer;
