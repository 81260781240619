import { t, Trans } from '@lingui/macro';
import { Field, Formik } from 'formik';
import React, { useCallback, useContext, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useLoads } from 'react-loads';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { UserDataContext } from '../../App';
import { I18nLanguageContext } from '../../components/I18nLoader';
import Loading from '../../components/Loading';
import SelectField from '../../components/SelectField';
import { Profile } from '../../declarations';
import { userService } from '../../services/UserService';
import feathersApp from '../../utils/feathers';
import { accountSchema } from '../../validationSchemas';
import { countryOptions } from './options';
import './styles.scss';

const UserProfile: React.SFC = props => {
  const [userProfile, setUserProfile] = useState<Profile>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    shippingAddress: {
      address1: '',
      address2: '',
      state: '',
      country: '',
      postcode: '',
      town: '',
    },
  });
  const { setUserData } = useContext(UserDataContext);
  const { i18n } = useContext(I18nLanguageContext);

  const getUserData = useCallback(async () => {
    const userId: any = userService.userId;
    const data = await feathersApp
      .service('users')
      .get(parseInt(userId, 10), { query: { includeAddress: true } });
    setUserProfile({
      ...data,
      shippingAddress: {
        ...data.shipping_address,
        country: data.shipping_address
          ? { value: data.shipping_address.country, label: data.shipping_address.country }
          : null,
      },
    });
  }, [setUserProfile]);

  const { isPending, load: updateUser } = useLoads(getUserData, {}, []);

  const accountSubmit = async (values: any) => {
    try {
      values.shippingAddress.country = values.shippingAddress.country.value;
      const userId: any = userService.userId;
      const data = await feathersApp.service('users').patch(userId, values);
      updateUser();
      setUserData(data);
      NotificationManager.success(
        i18n._(t`Your changes are successfully applied`),
        i18n._(t`Success`),
      );
    } catch (e) {
      NotificationManager.error(e.massage, i18n._(t`Error`));
    }
  };
  const countryOptionsResult = countryOptions.map((countryOption: any) => ({
    value: countryOption.name,
    label: countryOption.name,
  }));

  return (
    <div className="profile-container">
      {isPending ? (
        <Loading />
      ) : (
        <Formik
          validationSchema={accountSchema(i18n)}
          enableReinitialize
          initialValues={userProfile}
          onSubmit={accountSubmit}
        >
          {({ handleSubmit, handleChange, values, errors, touched, handleBlur, isValid }) => {
            return (
              <Form className="account-form" noValidate onSubmit={handleSubmit}>
                <div className="profile-title">
                  <Trans>Profile</Trans>
                </div>
                <div className="profile-row">
                  <div className="user-data">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label className="label">
                          <Trans>First Name</Trans>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="custom-input"
                          name="firstName"
                          placeholder="First name"
                          value={values.firstName}
                          onChange={handleChange}
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label className="label">
                          <Trans>Surname</Trans>
                        </Form.Label>
                        <Form.Control
                          name="lastName"
                          className="custom-input"
                          placeholder="Surname"
                          value={values.lastName}
                          onChange={handleChange}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCheckbox">
                        <Form.Label className="label">
                          <Trans>Email</Trans>
                        </Form.Label>
                        <Form.Control
                          className="custom-input"
                          type="email"
                          placeholder="Email"
                          readOnly
                          value={values.email}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                </div>
                <div className="delivery-wrap">
                  <div className="delivery-title">
                    <Trans>Delivery Address</Trans>
                  </div>
                  <Form.Group controlId="formGridAddress1">
                    <Form.Label className="label">
                      <Trans>Street Address</Trans>
                    </Form.Label>
                    <Form.Control
                      name="shippingAddress.address1"
                      className="custom-input"
                      placeholder="Street Address"
                      value={values.shippingAddress.address1}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.shippingAddress && errors.shippingAddress.address1)}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {touched?.shippingAddress?.address1 && errors?.shippingAddress?.address1}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group id="formGridAddress2">
                    <Form.Label className="label">
                      <Trans>Address (Optional)</Trans>
                    </Form.Label>
                    <Form.Control
                      className="custom-input"
                      placeholder="Address (Optional)"
                      name="shippingAddress.address2"
                      value={values.shippingAddress.address2}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.shippingAddress && errors.shippingAddress.address2)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.shippingAddress && errors.shippingAddress.address2}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress2">
                      <Form.Label className="label">
                        <Trans>Town/City</Trans>
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        placeholder="Town/City"
                        name="shippingAddress.town"
                        value={values.shippingAddress.town}
                        onChange={handleChange}
                        isInvalid={!!(errors.shippingAddress && errors.shippingAddress.town)}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched?.shippingAddress?.town && errors?.shippingAddress?.town}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPostcode">
                      <Form.Label className="label">
                        <Trans>Postcode</Trans>
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        placeholder="Postcode"
                        name="shippingAddress.postcode"
                        value={values.shippingAddress.postcode}
                        onChange={handleChange}
                        isInvalid={Boolean(
                          errors.shippingAddress && errors.shippingAddress.postcode,
                        )}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched?.shippingAddress?.postcode && errors?.shippingAddress?.postcode}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label className="label">
                        <Trans>Country</Trans>
                      </Form.Label>
                      <Field
                        name="shippingAddress.country"
                        options={countryOptionsResult}
                        component={SelectField}
                        isInvalid={Boolean(
                          errors.shippingAddress && errors.shippingAddress.country,
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.shippingAddress && errors.shippingAddress.country}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label className="label">
                        <Trans>State</Trans>
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        placeholder="State"
                        name="shippingAddress.state"
                        value={values.shippingAddress.state}
                        onChange={handleChange}
                        isInvalid={Boolean(errors.shippingAddress && errors.shippingAddress.state)}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {touched?.shippingAddress?.state && errors?.shippingAddress?.state}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </div>
                <div className="rectangle" />
                <div className="button-container">
                  <Button
                      type="submit"
                      className="primary custom-button save-button"
                      disabled={!isValid}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default UserProfile;
