import { LoginCredentials, RegistrationCredentials } from '../declarations';
import feathersApp from '../utils/feathers';

class UserService {
  public userId: string | null = null;
  public accessToken: string | null = null;
  public userEmail: string | null = null;

  constructor() {
    const savedUser = localStorage.getItem('userId');
    const savedAccessToken = localStorage.getItem('accessToken');
    const savedEmail = localStorage.getItem('userEmail');
    if (!savedUser || !savedAccessToken || !savedEmail) {
      return;
    }
    this.userId = savedUser;
    this.accessToken = savedAccessToken;
    this.userEmail = savedEmail;
  }

  public async login({ email, password }: LoginCredentials) {
    const user = await feathersApp.service('authentication').create({
      email,
      password,
      strategy: 'local',
    });

    if (user.user.permissions !== 'user') {
      throw new Error('Cannot login to shop with admin credentials');
    }

    this.setUserId(user);
    this.setToken(user);
    this.setUserEmail(user);
    return this.userId;
  }

  public async registration({
    firstName,
    lastName,
    phone,
    email,
    password,
  }: RegistrationCredentials) {
    const user = await feathersApp.service('users').create({
      email,
      firstName,
      lastName,
      password,
      phone,
    });

    this.setUserId(user);
    this.setToken(user);
    this.setUserEmail(user);
    return this.accessToken;
  }

  public async resetPassword({ password, token }: { password: string; token: any }) {
    await feathersApp.service('authmanagement').create({
      action: 'resetPwdLong',
      value: {
        password,
        token,
      },
    });
  }

  public async forgotPassword(email: string) {
    await feathersApp.service('authmanagement').create({
      action: 'sendResetPwd',
      value: {
        email,
      },
    });
  }

  public async passwordRecovery(password: string, oldPassword: string) {
    await feathersApp.service('authmanagement').create({
      action: 'passwordChange',
      value: {
        password,
        oldPassword,
        user: { email: this.userEmail },
      },
    });
  }

  public isAuthenticated() {
    return !!this.accessToken;
  }

  public async logout() {
    this.accessToken = null;
    this.userId = null;
    this.userEmail = null;
    localStorage.removeItem('userId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userEmail');
  }

  private setUserId(user: any) {
    if (user && user.user) {
      const userData = user.user;
      this.userId = userData.id;
      localStorage.setItem('userId', userData.id);
    }
  }

  private setToken(user: any) {
    if (user && user.accessToken) {
      this.accessToken = user.accessToken;
      localStorage.setItem('accessToken', user.accessToken);
    }
  }

  private setUserEmail(user: any) {
    if (user && user.user) {
      const userData = user.user;
      this.userEmail = userData.email;
      localStorage.setItem('userEmail', userData.email);
    }
  }
}

export const userService = new UserService();
