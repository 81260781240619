import { Trans } from '@lingui/macro';
import React, { useCallback, useContext, useMemo } from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ModalMenuContext } from '../../pages/Home';
import { userService } from '../../services/UserService';
import LoginDropdown from './loginDropdown';
import MobileUserDropdown from './MobileUserDropdown';
import { ScreenWidthContext } from '../../App';
import './styles.scss';
import UserDropdown from './UserDropdown';

const HomeHeader: React.FC = props => {
  // const onShowLoginForm = useContext(LoginFormContext);
  const history = useHistory();
  const { modalMenuOpen, changeModalMenuOpen } = useContext(ModalMenuContext);
  const { screenWidth } = useContext(ScreenWidthContext);

  const signin = useCallback(() => {
    history.push('/login');
  }, [history]);

  // const isLibraryLinkActive = useMemo(
  //   () => location.pathname === '/' || location.pathname.startsWith('/series'),
  //   [location.pathname],
  // );
  const isAuth = userService.isAuthenticated();

  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);

  return (
    <Navbar
      variant="dark"
      className={`navbar-container home-header ${modalMenuOpen ? 'modal-menu-open-header' : ''}`}
    >
      <Navbar.Brand
        as={Link}
        to="/"
        onClick={() => {
          changeModalMenuOpen(false);
        }}
      >
        <Logo className="home-header_main-logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {/*<Nav className="home-header_navigation">
          <Nav.Item
            as={Link}
            to="/"
            className={`home-header_navigation_link ${isLibraryLinkActive ? 'home-header_navigation_link--active': ''}`}
          >
            <Trans>Library</Trans>
          </Nav.Item>
          <Nav.Item as={Link} to="/" className="home-header_navigation_link">
            <Trans>How it works</Trans>
          </Nav.Item>
        </Nav>
    
        <Nav>
          <SearchWithPreview />
        </Nav>
        
        {isAuth && (
          <Nav.Link className="cart-icon" as={Link} to="/basket">
            <CartIcon />
          </Nav.Link>
        )}
          */}
        <Nav>
          {!isMobile && isAuth && <UserDropdown />}
          {isMobile && isAuth && <MobileUserDropdown />}

          {!isAuth && isMobile && <LoginDropdown />}

          {!isAuth && !isMobile && (
            <Button onClick={signin} variant="primary" className="custom-button primary login">
              <Trans>Sign in</Trans>
            </Button>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HomeHeader;
