import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { Link, useHistory } from 'react-router-dom';
import { ScreenWidthContext } from '../../../App';

import { I18nLanguageContext } from '../../../components/I18nLoader';
import ImageSelect from '../../../components/ImageSelect';
import Loading from '../../../components/Loading';
import SelectField from '../../../components/SelectField';
import { OPTION_TYPES, VARIABLE_TYPES } from '../../../constants';
import { Issue, Question, Series } from '../../../declarations';
import { userService } from '../../../services/UserService';
import feathersApp from '../../../utils/feathers';
import { ReactComponent as BackIcon } from '../back-icon.svg';
import MobilePreview from '../MobilePreview';

interface QuestionBlockProps {
  questionFields: any;
  questions: Question[];
  showUnregForm: () => void;
  issue: Issue | undefined;
  series: Series | undefined;
  issueId: any;
  isLoading: boolean;
  loadNewPreview: (values: any) => void;
  previewImages: string[];
  loadingPreview: boolean;
  assets: any[];
  assetName: any;
}

const QuestionBlock: React.FC<QuestionBlockProps> = props => {
  const {
    questionFields,
    questions,
    showUnregForm,
    issue,
    series,
    issueId,
    loadingPreview,
    loadNewPreview,
    previewImages,
    assets,
    isLoading,
    assetName,
  } = props;
  const { screenWidth } = useContext(ScreenWidthContext);

  const userId = userService.userId;
  const history = useHistory();
  const { language, i18n } = useContext(I18nLanguageContext);
  const [isVisibleMobilePreview, setVisibilityMobilePrevie] = useState<boolean>(false);
  const seriesId = series && series.id ? series.id : 0;
  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const isTablet = useMemo(() => screenWidth >= 426 && screenWidth <= 1024, [screenWidth]);
  const validateAnswers = useCallback(
    (values: any, rest: any) => {
      const answers = Object.keys(rest).map(id => {
        const intId = parseInt(id);
        const isTextAnswer = typeof values[intId] === 'string';
        if (!values[intId]) {
          throw new Error(i18n._(t`Answer all questions!`));
        }
        if (isTextAnswer) {
          if (values[intId].length > 12) {
            throw new Error(
              i18n._(t`Error! The maximum number of characters in this field is 12.`),
            );
          }
          return {
            variableId: intId,
            text: values[intId],
          };
        }
        return {
          variableId: intId,
          optionId: parseInt(values[intId]),
        };
      });
      if (!values.language) {
        throw new Error(i18n._(t`Select language!`));
      }
      return answers;
    },
    [i18n],
  );

  const createAutoOrder = useCallback(
    async (issueUrlId: string) => {
      try {
        const order = await feathersApp
          .service('orders')
          .create({ issuesIds: [issueUrlId] }, { query: { usePrevious: true } });
        history.push(`/review-order/${seriesId}/${order.id}`);
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error!`));
      }
    },
    [history, seriesId, i18n],
  );

  const onclickPreview = useCallback(
    (values: any) => {
      try {
        const isTextAnswer = Object.keys(values).find(value => typeof values[value] === 'string');
        if (isTextAnswer) {
          if (values[isTextAnswer].length > 12) {
            throw new Error(i18n._(t`The maximum number of characters in this field is 12.`));
          }
        }
        if (isMobile || isTablet) {
          setVisibilityMobilePrevie(true);
        }
        loadNewPreview(values);
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error!`));
      }
    },
    [isMobile, isTablet, loadNewPreview, i18n],
  );

  const submitForm = useCallback(
    async (values: any) => {
      try {
        const resultValue = { ...values };
        const { language: languageChoice, ...rest } = questionFields;
        for (const i in resultValue) {
          if (i !== 'language' && typeof resultValue[i] !== 'string') {
            resultValue[i] = resultValue[i].value;
          }
        }
        const answers = validateAnswers(resultValue, rest);
        const languageAnswer = parseInt(resultValue.language.value);
        if (userId) {
          if (answers.length) {
            await feathersApp.service('choice').create(answers);
          }
          await feathersApp
            .service('language-choice')
            .create({ languageId: languageAnswer, issueId });
          if (issue && issue.number === 1) {
            history.push(`/shipping/${seriesId}/${issueId}`);
          } else {
            createAutoOrder(issueId);
          }
        } else {
          showUnregForm();
          window.localStorage.setItem(
            'answers',
            JSON.stringify({
              series,
              answer: answers,
              language: { languageAnswer, issueId },
            }),
          );
        }
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error!`));
      }
    },
    [
      questionFields,
      createAutoOrder,
      history,
      issue,
      series,
      showUnregForm,
      seriesId,
      issueId,
      userId,
      validateAnswers,
      i18n,
    ],
  );

  const languageOptions =
    series &&
    series.languages &&
    series.languages.map((option: any) => ({
      value: option.id,
      label: option.name,
    }));

  const inputQuestions = useMemo(
    () => questions.filter(question => question.type === VARIABLE_TYPES.text),
    [questions],
  );

  const imageQuestions = useMemo(
    () => questions.filter(question => question.optionsType === OPTION_TYPES.img),
    [questions],
  );

  const selectQuestions = useMemo(
    () =>
      questions.filter(
        question =>
          question.type === VARIABLE_TYPES.selelct && question.optionsType === OPTION_TYPES.text,
      ),
    [questions],
  );

  const validateForm = useCallback(
    values => {
      const errors: any = {};
      const textValues = Object.keys(values).filter(
        // @ts-ignore
        id => !!inputQuestions.find(q => q.id == id),
      );
      textValues.forEach(v => {
        if (values[v].length === 0) {
          errors[v] = 'This field is required';
        } else if (values[v].length > 12) {
          errors[v] = 'The maximum number of characters in this field is 12.';
        }
      });
      return errors;
    },
    [inputQuestions],
  );

  return (
    <div className="personalize_form">
      <Formik
        initialValues={questionFields}
        onSubmit={submitForm}
        enableReinitialize
        validate={validateForm}
      >
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur }) => (
          <Form onSubmit={handleSubmit}>
            <div className="personalize_form_wrapper">
              <Link to={`/series/${issue && issue.seriesId}`} className="personalize_form_back">
                <BackIcon className="personalize_form_back_icon" />
                <Trans>Back to series</Trans>
              </Link>
              <h1 className="personalize_form_title">
                <Trans>Personalise</Trans>
              </h1>
              <p className="personalize_form_info">
                {isMobile || isTablet ? (
                  <Trans>
                    To personalise your comic simply answer the following few questions. Preview
                    your choices, make changes and when you are happy click order issue.
                  </Trans>
                ) : (
                  <Trans>Create and preview your very own comic.</Trans>
                )}
              </p>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {inputQuestions.length > 0 && (
                    <div className="personalize_form_questions">
                      {inputQuestions.map(question => (
                        <div key={question.id} className="personalize_form_questions_container">
                          <div className="personalize_form_questions_label">
                            {question.userDescription[language] || question.userDescription.default}
                          </div>
                          <Field
                            type="string"
                            name={question.id.toString()}
                            value={values[question.id]}
                            onChange={handleChange}
                            className="personalize_form_questions_input"
                            max={12}
                            required
                            onBlur={handleBlur}
                          />
                          <FormControl.Feedback style={{ display: 'block' }} type="invalid">
                            {touched[question.id.toString()] && errors[question.id.toString()]}
                          </FormControl.Feedback>
                        </div>
                      ))}
                    </div>
                  )}
                  {imageQuestions.length > 0 && (
                    <div className="personalize_form_questions">
                      {imageQuestions.map(question => {
                        const questionOptions = question.options.map((option: any) => ({
                          value: option.id,
                          label: option.imageUrl,
                        }));
                        return (
                          <div key={question.id} className="personalize_form_questions_container">
                            <div className="personalize_form_questions_label">
                              {question.userDescription[language] ||
                                question.userDescription.default}
                            </div>
                            <Field
                              name={question.id.toString()}
                              options={questionOptions}
                              component={ImageSelect}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectQuestions.length > 0 && (
                    <div className="personalize_form_questions">
                      {selectQuestions.map(question => {
                        const questionOptions = question.options.map((option: any) => ({
                          value: option.id,
                          label: option.name[language] || option.name.default,
                        }));
                        return (
                          <div key={question.id} className="personalize_form_questions_container">
                            <div className="personalize_form_questions_label">
                              {question.userDescription[language] ||
                                question.userDescription.default}
                            </div>
                            <Field
                              name={question.id.toString()}
                              options={questionOptions}
                              component={SelectField}
                            />
                          </div>
                        );
                      })}
                      {languageOptions && (
                        <div className="personalize_form_questions_laguage-conatiner">
                          <div className="personalize_form_questions_label">
                            <Trans>Language</Trans>
                          </div>
                          <Field
                            name="language"
                            options={languageOptions}
                            component={SelectField}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="personalize_form_buttons">
              {!isMobile && !isTablet ? (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    className="personalize_form_buttons_order primary"
                  >
                    <Trans>Order Issue</Trans>
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onclickPreview(values)}
                    className="personalize_form_buttons_preview gray"
                  >
                    <Trans>Preview</Trans>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    className="personalize_form_buttons_order primary"
                  >
                    <Trans>Order Issue</Trans>
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onclickPreview(values)}
                    className="personalize_form_buttons_preview gray"
                  >
                    <Trans>Preview</Trans>
                  </Button>
                </>
              )}
            </div>

            {(isMobile || isTablet) && isVisibleMobilePreview && issue && series && (
              <MobilePreview
                issueTitle={issue.title[language] || issue.title.default}
                seriesTitle={series.title[language] || series.title.default}
                show={isVisibleMobilePreview}
                handleClose={() => setVisibilityMobilePrevie(false)}
                onSubmit={() => submitForm(values)}
                images={previewImages}
                loading={loadingPreview}
                assets={assets || []}
                assetName={assetName}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionBlock;
