import React from 'react';
import Select from 'react-select';
import './styles.scss';

const SelectField = (FieldProps: any) => {
  return (
    <Select
      className="custom_select"
      classNamePrefix="select"
      options={FieldProps.options}
      {...FieldProps.field}
      isSearchable={false}
      onChange={option => FieldProps.form.setFieldValue(FieldProps.field.name, option)}
    />
  );
};

export default SelectField;
