import { Trans } from '@lingui/macro';
import React from 'react';

export const avatarOptions = [
  {
    id: 1,
    value: 'white',
    url: '/images/white.png',
    title: null,
  },
  {
    id: 2,
    value: 'black',
    url: '/images/black.png',
    title: null,
  },
];

export const languageOptions = [
  {
    id: 1,
    value: 'english',
    title: <Trans>english</Trans>,
  },
  {
    id: 2,
    value: 'german',
    title: <Trans>german</Trans>,
  },
  {
    id: 3,
    value: 'espanol',
    title: <Trans>espanol</Trans>,
  },
];

export const attackOptions = [
  {
    id: 1,
    value: 'web sling',
    fileName: 'websling.png',
    title: <Trans>web sling</Trans>,
    background: 'web',
  },
  {
    id: 2,
    value: 'sound wave',
    fileName: 'soundwave.png',
    title: <Trans>sound wave</Trans>,
    background: 'sound',
  },
  {
    id: 3,
    value: 'poison',
    fileName: 'poison.png',
    title: <Trans>poison</Trans>,
    background: 'poison',
  },
];

export const featuresOptions = [
  {
    id: 1,
    title: <Trans>Choose your adventure & character</Trans>,
    description: (
      <Trans>
        Choose to battle along side heros like Spiderman, Captain America or Captain Marvel.
      </Trans>
    ),
    url: '/images/skins.jpg',
    mobileUrl: '/images/mobile-skins.jpg',
  },
  {
    id: 2,
    title: <Trans>Make the important decisions</Trans>,
    description: (
      <Trans>
        We will give you some options to choose from that will ultimatly change how the story will
        unfold.
      </Trans>
    ),
    url: '/images/decisions.jpg',
    mobileUrl: '/images/mobile-decisions.jpg',
  },
  {
    id: 3,
    title: <Trans>Enjoy your very own printed comic</Trans>,
    description: (
      <Trans>
        Your adventure will be printed and shipped to you to own and enjoy over and over!
      </Trans>
    ),
    url: '/images/comics.jpg',
    mobileUrl: '/images/mobile-comics.jpg',
  },
];

export const advantagesOptions = [
  {
    id: 1,
    title: <Trans>International Delivery</Trans>,
    description: (
      <Trans>We ship all around the world so that nobody needs to miss out on the fun'</Trans>
    ),
    url: '/images/advantage-delivery.png',
    mobileUrl: '/images/mobile-advantage-delivery.png',
    className: 'delivery',
  },
  {
    id: 2,
    title: <Trans>Weekly Issues</Trans>,
    description: (
      <Trans>
        One you have ordered your issue you should receive your comic within 3-5 working days.
      </Trans>
    ),
    url: '/images/advantage-weekly.png',
    mobileUrl: '/images/mobile-advantage-weekly.png',
    className: 'issues',
  },
  {
    id: 3,
    title: <Trans>Quality Printing</Trans>,
    description: (
      <Trans>We use only the best print providers to ensure you get the very best quality.</Trans>
    ),
    url: '/images/advantage-quality.png',
    mobileUrl: '/images/mobile-advantage-quality.png',
    className: 'quality',
  },
];
