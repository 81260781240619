import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { userService } from '../../services/UserService';

const PrivateRoute = ({ component, ...rest }: any) => {
  const isAuth = userService.isAuthenticated();
  const routeComponent = (props: any) =>
    isAuth ? React.createElement(component, props) : <Redirect to={{ pathname: '/' }} />;
  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
