import React from 'react';
import { Language } from '../../declarations';
import { ReactComponent as EnglishIcon } from '../../images/british.svg';
import { ReactComponent as GermanIcon } from '../../images/german.svg';
import { ReactComponent as SpanishIcon } from '../../images/spanish.svg';
import './styles.scss';

interface LanguagesComponentTypes {
  languages: Language[] | undefined;
}

const getLanguageIcon = (languageName: string) => {
  switch (languageName) {
    case 'English':
      return <EnglishIcon />;
    case 'German':
      return <GermanIcon />;
    case 'Spanish':
      return <SpanishIcon />;
    default:
      return null;
  }
};

const LanguagfesComponent: React.FC<LanguagesComponentTypes> = props => {
  const { languages } = props;
  return (
    <div className="languages-container">
      {languages &&
        languages.map(language => (
          <div className="language" key={language.id}>
            {getLanguageIcon(language.name)}
          </div>
        ))}
    </div>
  );
};

export default LanguagfesComponent;
