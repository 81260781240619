import React, { useContext, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Login from './Login';
import { ScreenWidthContext } from '../../App';

import './styles.scss';

export interface LoginModalPropsTypes {
  onClose: () => void;
  isVisible: boolean;
}

const LoginModal: React.FC<LoginModalPropsTypes> = props => {
  const { onClose, isVisible } = props;
  const { screenWidth } = useContext(ScreenWidthContext);

  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);

  return (
    <Modal className="login-modal" show={isVisible} onHide={onClose} centered={!isMobile}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Login onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
