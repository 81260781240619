import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalMenuContext } from '../../../pages/Home';
import Login from '../../Login';

const LoginDropdown: React.FC = () => {
  const { modalMenuOpen, changeModalMenuOpen } = useContext(ModalMenuContext);

  return (
    <div>
      <div
        id="nav-icon"
        className={`${modalMenuOpen ? 'open' : ''}`}
        onClick={() => changeModalMenuOpen(!modalMenuOpen)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Modal show={modalMenuOpen} onHide={() => null} className="mobile-modal">
        <Modal.Body>
          <Login isModal={true} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginDropdown;
