import { t, Trans } from '@lingui/macro';
import React, { useCallback, useContext } from 'react';
import { useLoads } from 'react-loads';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { I18nLanguageContext } from '../../components/I18nLoader';
import feathersApp from '../../utils/feathers';
import CardSection from './CardSection';
import Loading from "../../components/Loading";
import {Modal} from "react-bootstrap";

interface CheckoutFormProps {
  orderId: string;
  seriesId: string;
  stripe?: any;
}

const CheckoutForm: React.FC<CheckoutFormProps> = props => {
  const { orderId, seriesId, stripe } = props;
  const history = useHistory();
  const { i18n } = useContext(I18nLanguageContext);

  const purchaseSeries = useCallback(
    async (cardToken: any) => {
      try {
        const { paymentId } = await feathersApp
          .service('purchased-series')
          .create({ cardToken, seriesIds: [seriesId] });
        await feathersApp.service('orders').patch(orderId, { status: 'CONFIRMED' });
        NotificationManager.success(i18n._(t`Successfully purchased!`), i18n._(t`Success!`));
        history.push(`/confirmation/${seriesId}/${orderId}/${paymentId}`);
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error!`));
      }
    },
    [orderId, seriesId, history, i18n],
  );

  const createToken = useCallback(
    async (cardName: any) => {
      // @ts-ignore
      const { error, token } = await stripe.createToken({ type: 'card', name: cardName });
      if (error) {
        return NotificationManager.error(error.message, i18n._(t`Error!`));
      }
      await purchaseSeries(token.id);
    },
    [purchaseSeries, stripe, i18n],
  );

  const { isPending, load } = useLoads(createToken, { defer: true }, []);
  return (
    <div className="pay-container">
      <div className="pay-title">
        <Trans>Payment Method</Trans>
      </div>
      <Modal show={isPending} centered={true} className="payment-loading">
          <Loading/>
      </Modal>
      <div className="method-button">
        <div>
          <Trans>Pay with Card</Trans>
        </div>
      </div>
      <CardSection createToken={load} isPending={isPending} orderId={orderId} seriesId={seriesId} />
    </div>
  );
};

export default injectStripe(CheckoutForm);
