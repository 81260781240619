import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { Formik } from 'formik';
import React, { useCallback, useContext } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useLoads } from 'react-loads';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { I18nLanguageContext } from '../../components/I18nLoader';
import Loading from '../../components/Loading';
import { userService } from '../../services/UserService';

const PasswordRecovery: React.FC<RouteComponentProps> = props => {
  const { history } = props;
  const { i18n } = useContext(I18nLanguageContext);

  const shema = Yup.object({
    oldPassword: Yup.string().required(i18n._(t`Old Password is required!`)),
    password: Yup.string().required(i18n._(t`Password is required!`)),
    passwordConfirmation: Yup.string()
      .required(i18n._(t`Please repeat password!`))
      .oneOf([Yup.ref('password'), null], i18n._(t`Passwords must match!`)),
  });

  const recoveryPassword = useCallback(
    async (values: any) => {
      try {
        await userService.passwordRecovery(values.password, values.oldPassword);
        NotificationManager.success(
          i18n._(t`Your password successfuly changed!`),
          i18n._(t`Success`),
        );
        history.push('/');
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error!`));
      }
    },
    [history, i18n],
  );

  const { isPending, load } = useLoads(recoveryPassword, { defer: true }, []);
  return (
    <div className="reset-password-container">
      {isPending ? (
        <Loading />
      ) : (
        <div className="password-form">
          <Formik
            validationSchema={shema}
            initialValues={{ oldPassword: '', password: '', passwordConfirmation: '' }}
            enableReinitialize
            onSubmit={load}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formBasicPassword">
                      <Form.Label>
                        <Trans>Old Password</Trans>
                      </Form.Label>
                      <I18n>
                        {({ i18n }) => (
                          <Form.Control
                            type="password"
                            name="oldPassword"
                            placeholder={i18n._(t`Enter old password`)}
                            value={values.oldPassword}
                            onChange={handleChange}
                            isInvalid={!!(errors.oldPassword && touched.oldPassword)}
                          />
                        )}
                      </I18n>
                      <Form.Control.Feedback type="invalid">
                        {touched.oldPassword && errors.oldPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formBasicPassword">
                      <Form.Label>
                        <Trans>New Password</Trans>
                      </Form.Label>
                      <I18n>
                        {({ i18n }) => (
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder={i18n._(t`Enter new password`)}
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!(errors.password && touched.password)}
                          />
                        )}
                      </I18n>
                      <Form.Control.Feedback type="invalid">
                        {touched.password && errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicPassword">
                      <Form.Label>
                        <Trans>Repeat new Password</Trans>
                      </Form.Label>
                      <I18n>
                        {({ i18n }) => (
                          <Form.Control
                            type="password"
                            name="passwordConfirmation"
                            placeholder={i18n._(t`Repeat a new password`)}
                            value={values.passwordConfirmation}
                            onChange={handleChange}
                            isInvalid={
                              !!(errors.passwordConfirmation && touched.passwordConfirmation)
                            }
                          />
                        )}
                      </I18n>
                      <Form.Control.Feedback type="invalid">
                        {touched.passwordConfirmation && errors.passwordConfirmation}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <Button variant="primary" type="submit">
                    <Trans>Set new password</Trans>
                  </Button>
                  <Button variant="link" as={Link} to="/">
                    <Trans>Back</Trans>
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default PasswordRecovery;
