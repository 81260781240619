import { Trans } from '@lingui/macro';
import React from 'react';
import { Issue } from '../../declarations';
import './styles.scss';

interface DeliveryStatusTypes {
  issue: Issue;
}

const DeliveryStatusComponent: React.FC<DeliveryStatusTypes> = props => {
  const { issue } = props;
  return (
    <div className="delivery-container">
      <div className="delivery-point-container">
        <div className={`delivery-point active`} />
        <span className="delivery-point-text">
          <Trans>Issue available</Trans>
        </span>
      </div>
      <div className={`delivery-process${issue.status !== 'AVAILABLE' ? ' active' : ''}`} />
      <div className="delivery-point-container">
        <div className={`delivery-point${issue.status !== 'AVAILABLE' ? ' active' : ''}`} />
        <span className="delivery-point-text">
          <Trans>Ordered</Trans>
        </span>
      </div>
      <div
        className={`delivery-process${
          issue.status === 'SHIPPED' || issue.status === 'DELIVERED' ? ' active' : ''
        }`}
      />
      <div className="delivery-point-container">
        <div
          className={`delivery-point${
            issue.status !== 'AVAILABLE' && issue.status !== 'ORDERED' ? ' active' : ''
          }`}
        />
        <span className="delivery-point-text">
          <Trans>Shipped</Trans>
        </span>
      </div>
    </div>
  );
};

export default DeliveryStatusComponent;
