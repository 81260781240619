import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as GermanIcon } from '../../../images/de.svg';
import { ReactComponent as EnglishIcon } from '../../../images/en.svg';
import { ReactComponent as SpanishIcon } from '../../../images/es.svg';
import { useClickOutside } from "../../../utils/blurEffect";
import { I18nLanguageContext } from '../../I18nLoader';
import './styles.scss';

type Language = 'en' | 'de' | 'es';

const LANGUAGES_NAMES = {
  en: 'English',
  de: 'German',
  es: 'Spanish',
};

interface LanguageDropdownItemPropsType {
  name: string;
}

const LanguageDropdownItem: React.FC<LanguageDropdownItemPropsType> = (props) => {
  const { name } = props;
  const Icon = useMemo(() => {
    switch (name) {
      case 'English':
        return EnglishIcon;
      case 'German':
        return GermanIcon;
      case 'Spanish':
        return SpanishIcon;
      default:
        return EnglishIcon;
    }
  }, [name]);
  return (
    <p className="language-select_item">
      <Icon className="language-select_item_icon" />
      {name}
    </p>
  );
};

const LanguageSelect: React.FC = (props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = useCallback(() => setDropdownVisible(!dropdownVisible), [dropdownVisible]);
  const { setLanguage, language } = useContext(I18nLanguageContext);

  const onLanguageSelect = useCallback((lang: Language) => {
    setLanguage(lang);
    setDropdownVisible(false);
  }, [setLanguage]);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, useCallback(() => setDropdownVisible(false),[]));

  return (
    <div className="language-select">
      <div className="language-select_toggle" onClick={toggleDropdown}>
        <LanguageDropdownItem name={LANGUAGES_NAMES[language as Language]} />
      </div>
      <Dropdown
        show={dropdownVisible}
        drop="up"
        ref={dropdownRef}
      >
        <Dropdown.Menu className="language-select_dropdown-menu">
          <Dropdown.Item
            className="language-select_dropdown-menu_item"
            onClick={useCallback(() => onLanguageSelect('en'), [onLanguageSelect])}
          >
            <LanguageDropdownItem name="English" />
          </Dropdown.Item>
          <Dropdown.Item
            className="language-select_dropdown-menu_item"
            onClick={useCallback(() => onLanguageSelect('de'), [onLanguageSelect])}
          >
            <LanguageDropdownItem name="German" />
          </Dropdown.Item>
          <Dropdown.Item
            className="language-select_dropdown-menu_item"
            onClick={useCallback(() => onLanguageSelect('es'), [onLanguageSelect])}
          >
            <LanguageDropdownItem name="Spanish" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LanguageSelect;
