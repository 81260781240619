import { Trans } from '@lingui/macro';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLoads } from 'react-loads';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { ScreenWidthContext } from '../../App';
import { I18nLanguageContext } from '../../components/I18nLoader';
import Loading from '../../components/Loading';
import { Series } from '../../declarations';
import { userService } from '../../services/UserService';
import feathersApp from '../../utils/feathers';
import DesctopSubscription from './DesctopSubscription';
import MobileSubscription from './MobileSubscription';
import './styles.scss';

const Subscription: React.FC<RouteComponentProps> = props => {
  const { history } = props;
  const [allSeries, setSeries] = useState<Series[]>([]);
  const [purshasedSeriesNumber, setPurshasedSeriesNumber] = useState();
  const { screenWidth } = useContext(ScreenWidthContext);

  const isMobile = useMemo(() => screenWidth <= 599, [screenWidth]);

  const { language, i18n } = useContext(I18nLanguageContext);

  const parseUrlParams = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlEmail = urlParams.get('email');
    if (urlEmail) {
      const codeEmail = encodeURIComponent(urlEmail);
      const userEmail = userService.userEmail;
      if (urlEmail !== userEmail) {
        userService.logout();
        history.push(`/login?email=${codeEmail}&redirect_to=/account/subscription`);
      } else {
        history.push(`/account/subscription`);
      }
    }
  }, [history]);

  useEffect(() => {
    parseUrlParams();
  }, [parseUrlParams]);

  const getAllPurchasedSeries = useCallback(async () => {
    try {
      const series = await feathersApp.service('purchased-series').find({
        query: {
          includeSeries: true,
          $sort: {
            createdAt: -1,
          },
        },
      });
      const seriesWithIssues = series.data.filter(
        (seriesItem: any) => seriesItem.series && seriesItem.series.issues.length,
      );
      setSeries(seriesWithIssues.map((ps: any) => ({ ...ps.series, purchasedAt: ps.createdAt })));
      setPurshasedSeriesNumber(series.data.length);
    } catch (e) {
      NotificationManager.error(e.message, i18n._(`Error!`));
    }
  }, [setSeries, i18n]);

  const { isPending } = useLoads(getAllPurchasedSeries, {}, []);

  return (
    <div className="subscription-container">
      {isMobile ? (
        <div className="tabs-container">
          <div className={`login-container__sign`}>
            <div>
              <Trans>Active</Trans>
            </div>
          </div>
          <div className={`login-container__sign login-container__disabled`}>
            <div />
          </div>
        </div>
      ) : (
        <div className="subscription-tabs">
          <div className="subscription-tab active">
            <Trans>Active</Trans>
          </div>
        </div>
      )}
      {isPending ? (
        <Loading />
      ) : isMobile ? (
        <MobileSubscription
          purshasedSeriesNumber={purshasedSeriesNumber}
          allSeries={allSeries}
          language={language}
        />
      ) : (
        <DesctopSubscription
          purshasedSeriesNumber={purshasedSeriesNumber}
          allSeries={allSeries}
          language={language}
        />
      )}
    </div>
  );
};

export default Subscription;
