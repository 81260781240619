import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as EyeIcon } from '../../images/eye_icon.svg';
import { ModalMenuContext } from '../../pages/Home';
import { userService } from '../../services/UserService';
import feathersApp from '../../utils/feathers';
import { registrationSchema, signinSchema } from '../../validationSchemas';
import { I18nLanguageContext } from '../I18nLoader';
import './styles.scss';

interface LoginPropsType {
  isModal: boolean;
}
const LoginComponent: React.FC<LoginPropsType> = props => {
  // @ts-ignore
  const { isModal } = props;
  const history = useHistory();
  const [type, setType] = useState<string>('password');
  const [componentType, setComponentType] = useState<string>('signin');
  const [redirectFromEmail, setRedirectFromEmail] = useState<string>('');
  const [initialValues] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const { changeModalMenuOpen } = useContext(ModalMenuContext);
  const { i18n } = useContext(I18nLanguageContext);
  const changeType = () => setType(type === 'password' ? 'text' : 'password');

  const parseUrlParams = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect_to = urlParams.get('redirect_to');
    if (redirect_to) {
      setRedirectFromEmail(redirect_to);
    }
  }, []);

  useEffect(() => {
    parseUrlParams();
  }, [parseUrlParams]);

  const sentAnswers = useCallback(
    async (questionJSON: any) => {
      try {
        const answers = questionJSON.answer;
        const languageAnswer = questionJSON.language.languageAnswer;
        const issueId = questionJSON.language.issueId;
        const series = questionJSON.series;

        await feathersApp.service('choice').create(answers);
        await feathersApp
          .service('language-choice')
          .create({ languageId: languageAnswer, issueId });
        history.push(`/shipping/${series && series.id}/${issueId}`);
        localStorage.removeItem('answers');
      } catch (e) {
        console.log(e.message, 'e');
      }
    },
    [history],
  );

  const loginSubmit = useCallback(
    async (values: any) => {
      try {
        await userService.login(values);
        const question = window.localStorage.getItem('answers') || '{}';
        const questionJSON = JSON.parse(question);
        if (questionJSON.answer) {
          sentAnswers(questionJSON);
        } else {
          history.push(redirectFromEmail || '/');
        }
        changeModalMenuOpen(false);
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error!`));
      }
    },
    [redirectFromEmail, sentAnswers, history, changeModalMenuOpen, i18n],
  );

  const registerSubmit = useCallback(
    async (values: any) => {
      try {
        await userService.registration(values);
        if (isModal) {
          loginSubmit(values);
        } else {
          history.push('/');
          changeModalMenuOpen(false);
        }
        NotificationManager.success(
          i18n._(t`You have successfully registered`),
          i18n._(t`Success!`),
        );
      } catch (e) {
        const message = e.errors[0] ? e.errors[0].message : e.message;
        NotificationManager.error(
          message[0].toUpperCase() + message.slice(1) + '!',
          i18n._(t`Error!`),
        );
      }
    },
    [history, changeModalMenuOpen, isModal, loginSubmit, i18n],
  );
  const isSignIn = componentType === 'signin';

  return (
    <div className="login-container">
      <Form.Row>
        <div
          className={`login-container__sign ${isSignIn ? '' : 'login-container__disabled'}`}
          onClick={() => setComponentType('signin')}
        >
          <Trans>Sign In</Trans>
        </div>
        <div
          className={`login-container__sign ${isSignIn ? 'login-container__disabled' : ''}`}
          onClick={() => setComponentType('signup')}
        >
          <Trans>Sign Up</Trans>
        </div>
      </Form.Row>
      <Formik
        validationSchema={isSignIn ? signinSchema(i18n) : registrationSchema(i18n)}
        initialValues={initialValues}
        onSubmit={isSignIn ? loginSubmit : registerSubmit}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur }) => {
          return isSignIn ? (
            <div className="login-wrap">
              <Form
                className={`login-container__login-form
                ${isModal ? 'login-container__login-form_modal' : ''}
                login-container__login-form_signin`}
                noValidate
                onSubmit={handleSubmit}
              >
                <Form.Group as={Col} controlId="formGridEmail">
                  <I18n>
                    {({ i18n }) => (
                      <Form.Control
                        type="email"
                        placeholder={i18n._(t`Email`)}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={Boolean(errors.email && touched.email)}
                        onBlur={handleBlur}
                      />
                    )}
                  </I18n>
                  <Form.Control.Feedback type="invalid">
                    {touched.email && errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPassword">
                  <I18n>
                    {({ i18n }) => (
                      <Form.Control
                        type={type}
                        placeholder={i18n._(t`Password`)}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={Boolean(errors.password && touched.password)}
                        onBlur={handleBlur}
                      />
                    )}
                  </I18n>
                  <Form.Control.Feedback type="invalid">
                    {touched.password && errors.password}
                  </Form.Control.Feedback>
                  <EyeIcon className="eye-icon" onClick={changeType} />
                </Form.Group>
                <Row className="login-container__login-form_modal_need-help-row">
                  <Link
                    to="/forgot"
                    className="need-help-button"
                    onClick={() => changeModalMenuOpen(false)}
                  >
                    <Trans>Need help?</Trans>
                  </Link>
                </Row>
                <Button variant="primary" type="submit" className="signin-button primary">
                  <Trans>Sign In</Trans>
                </Button>
              </Form>
              {!isModal && (
                <Button
                  variant="primary"
                  className="create-account-button gray"
                  onClick={() => setComponentType('signup')}
                >
                  <Trans>Create Account</Trans>
                </Button>
              )}
            </div>
          ) : (
            <Form
              className={`login-container__login-form
            ${isModal ? 'login-container__login-form_modal' : ''}
            login-container__login-form_signup`}
              noValidate
              onSubmit={handleSubmit}
            >
              <Form.Group as={Col} controlId="formGridFirstName" className="signup-row">
                <I18n>
                  {({ i18n }) => (
                    <Form.Control
                      type="string"
                      name="firstName"
                      placeholder={i18n._(t`First name`)}
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.firstName && touched.firstName)}
                      onBlur={handleBlur}
                    />
                  )}
                </I18n>
                <Form.Control.Feedback type="invalid">
                  {touched.firstName && errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridLastName" className="signup-row">
                <I18n>
                  {({ i18n }) => (
                    <Form.Control
                      type="string"
                      name="lastName"
                      placeholder={i18n._(t`Last name`)}
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.lastName && touched.lastName)}
                      onBlur={handleBlur}
                    />
                  )}
                </I18n>
                <Form.Control.Feedback type="invalid">
                  {touched.lastName && errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail" className="signup-row">
                <I18n>
                  {({ i18n }) => (
                    <Form.Control
                      type="string"
                      name="email"
                      placeholder={i18n._(t`Email`)}
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.email && touched.email)}
                      onBlur={handleBlur}
                    />
                  )}
                </I18n>
                <Form.Control.Feedback type="invalid">
                  {touched.email && errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPassword" className="signup-row">
                <I18n>
                  {({ i18n }) => (
                    <Form.Control
                      type={type}
                      name="password"
                      placeholder={i18n._(t`Password`)}
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={Boolean(errors.password && touched.password)}
                      onBlur={handleBlur}
                    />
                  )}
                </I18n>
                <Form.Control.Feedback type="invalid">
                  {touched.password && errors.password}
                </Form.Control.Feedback>
                <EyeIcon className="eye-icon" onClick={changeType} />
              </Form.Group>

              <Button className="signup-button primary" variant="primary" type="submit">
                <Trans>Create Account</Trans>
              </Button>
              {!isModal && (
                <Row>
                  <p className="already">
                    <Trans>Already have an account?</Trans>
                  </p>
                  <p className="signin-link" onClick={() => setComponentType('signin')}>
                    <Trans>Sign in</Trans>
                  </p>
                </Row>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoginComponent;
