import { Trans } from '@lingui/macro';
import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ScreenWidthContext } from '../../App';
import { Order } from '../../declarations';

import './styles.scss';
import moment from "moment";
interface OrderDataProps {
  order: Order;
  seriesId: string;
}

const OrderData: React.FC<OrderDataProps> = props => {
  const { order, seriesId } = props;
  const { screenWidth } = useContext(ScreenWidthContext);

  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const isTablet = useMemo(() => screenWidth >= 426 && screenWidth <= 1024, [screenWidth]);
  const deliveryDate = moment()
      .add(7, 'days')
      .format(`${isMobile || isTablet ? 'MMM D YYYY' : 'D MMMM YYYY'}`);

  return (
    <div className="order-data">
      <div className="row-2">
        <div className="order-title">
          <Trans>Shipping</Trans>
        </div>
        <div className="order-text">{order.order_shipment.email}</div>
        <div className="order-text">
          {order.order_shipment.address1 +
            ', ' +
            order.order_shipment.state +
            ', ' +
            order.order_shipment.town +
            ', ' +
            order.order_shipment.postcode +
            ', ' +
            order.order_shipment.country}
        </div>
      </div>
      <div className="row-2">
        <div className="order-title">
          <Trans>Method</Trans>
        </div>
        <div className="order-text">
          <Trans>Standard shipping</Trans>&nbsp;
          {isMobile || isTablet ? (
            <span className="mobile-shipping-date">
              <Trans>- Get it by </Trans>&nbsp;{deliveryDate}
            </span>
          ) : (
              <> <Trans>(Get it by </Trans>&nbsp;{deliveryDate}) </>
          )}
        </div>
      </div>
      <div className="row-2">
        <Link to={`/shipping-edit/${seriesId}/${order.id}`} className="order-text link">
          <Trans>Edit</Trans>
        </Link>
      </div>
    </div>
  );
};

export default OrderData;
