import { setupI18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
interface HandlesImport {
  handleImport: (language: string) => Promise<any>;
}

export const I18nLanguageContext = React.createContext<{
  language: string;
  setLanguage: any;
  i18n: any;
}>({
  language: 'en',
  setLanguage: () => {},
  i18n: undefined,
});

export const I18nLoader: React.FC<HandlesImport> = React.memo(props => {
  const [language, setLanguage] = useLocalStorage('language', 'en');
  const [catalogs, setCatalogs] = useState<any>({});
  const { children, handleImport } = props;

  const fetchLocale = useCallback(async () => {
    const catalog = handleImport ? await handleImport(language) : {};
    setCatalogs({
      ...catalogs,
      [language]: catalog,
    });
  }, [catalogs, handleImport, language]);

  useEffect(() => {
    if (!catalogs[language]) {
      fetchLocale();
    }
  }, [language, catalogs, fetchLocale]);

  const i18n = setupI18n({
    catalogs,
    language,
  });

  const value = useMemo(
    () => ({
      language,
      setLanguage,
      i18n,
    }),
    [language, setLanguage, i18n],
  );

  return (
    <I18nLanguageContext.Provider value={value}>
      <I18nProvider language={language} catalogs={catalogs} i18n={i18n}>
        {children}
      </I18nProvider>
    </I18nLanguageContext.Provider>
  );
});

export default I18nLoader;
