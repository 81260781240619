import { Trans } from '@lingui/macro';
import moment from 'moment';
import React, { useMemo, useContext, useState } from 'react';
import { Accordion, Button, Card, Table } from 'react-bootstrap';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ScreenWidthContext } from '../../App';
import DeliveryStatusComponent from '../../components/DeliveryStatusComponent';
import { Series } from '../../declarations';
import './styles.scss';

interface MobileSubscriptionProps {
  purshasedSeriesNumber: any;
  allSeries: Series[];
  language: string;
}

const DesctopSubscription: React.FC<MobileSubscriptionProps> = props => {
  const { purshasedSeriesNumber, allSeries, language } = props;
  const { screenWidth } = useContext(ScreenWidthContext);
  const [currentItem, setCurrentItem] = useState({ index: 0, open: true });
  const isTablet = useMemo(() => screenWidth >= 426 && screenWidth <= 1024, [screenWidth]);

  const cardToggle = (index: any) => {
    if (index === currentItem.index) {
      setCurrentItem({ index, open: !currentItem.open });
    } else {
      setCurrentItem({ index, open: true });
    }
  };
  return (
    <Accordion className="series-table-container" defaultActiveKey="0">
      {purshasedSeriesNumber !== 0 ? (
        allSeries.map((seriesItem: any, index: any) => {
          return (
            <Card
              key={index}
              onClick={(e: any) => {
                e.stopPropagation();
                cardToggle(index);
              }}
            >
              <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                <div className="series-row">
                  <div className="series-data-coll ">
                    <div>
                      {currentItem.index === index && currentItem.open ? (
                        <FaCaretDown
                          className="down-icon"
                          onClick={() => setCurrentItem({ index, open: false })}
                          aria-expanded={currentItem.open}
                        />
                      ) : (
                        <FaCaretRight
                          className="down-icon"
                          onClick={() => setCurrentItem({ index, open: true })}
                          aria-expanded={currentItem.open}
                        />
                      )}
                    </div>
                    <div className="series-text bold ">
                      {seriesItem.title[language] || seriesItem.title.default}
                    </div>
                  </div>
                  <div className="series-data">
                    {!isTablet && (
                      <>
                        <div className="series-data-coll">
                          <div className="series-text-title">
                            <Trans>Order No.</Trans>
                          </div>
                          <div className="series-text">{seriesItem.id}</div>
                        </div>
                        <div className="series-data-coll">
                          <div className="series-text-title">
                            <Trans>Date subscribed</Trans>
                          </div>
                          <div className="series-text">
                            {moment(seriesItem.purchasedAt).format('MM/DD/YY')}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="series-data-coll">
                      <div className="series-text">
                        {seriesItem.issues && seriesItem.issues.length}
                      </div>
                      <div className="series-text">
                        <Trans>Issues</Trans>
                      </div>
                    </div>
                    <div className="series-data-coll">
                      <div className={`series-text ${isTablet ? '' : 'bold'}`}>$</div>
                      <div className={`series-text ${isTablet ? '' : 'bold'}`}>
                        {seriesItem.price.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body>
                  {isTablet && (
                    <>
                      <div className="line" />
                      <div className="series-tablet-row">
                        <div className="series-data-coll">
                          <div className="series-text-title">
                            <Trans>Order No.</Trans>
                          </div>
                          <div className="series-text">{seriesItem.id}</div>
                        </div>
                        <div className="series-data-coll">
                          <div className="series-text-title">
                            <Trans>Date subscribed</Trans>
                          </div>
                          <div className="series-text">
                            {moment(seriesItem.purchasedAt).format('MM/DD/YY')}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <Table striped hover className="series-table">
                    <thead className="table-header">
                      <tr className="first-row">
                        <th className="first-custom-th" />
                        <th className="first-custom-th">
                          <Trans>Issue No.</Trans>
                        </th>
                        <th className="first-custom-th">
                          <Trans>Status</Trans>
                        </th>
                        <th className="first-custom-th">
                          <Trans>Price</Trans>
                        </th>
                        <th className="first-custom-th" />
                      </tr>
                    </thead>
                    <tbody className="custom-body">
                      {seriesItem.issues &&
                        seriesItem.issues.map((issueItem: any, index: number) => {
                          const lenghtActiveIssues =
                            seriesItem &&
                            seriesItem.issues &&
                            seriesItem.issues.filter(
                              (issue: any) => issue.status !== 'NOT_AVAILABLE',
                            );
                          return (
                            <>
                              <tr
                                className={`${
                                  (issueItem.status === 'AVAILABLE' && isTablet) ||
                                  index === seriesItem.issues.length - 1
                                    ? 'without-border'
                                    : 'custom-row'
                                }`}
                                key={issueItem.id}
                              >
                                {issueItem.status !== 'NOT_AVAILABLE' ? (
                                  <>
                                    <td className="custom-th">
                                      <div className="center">
                                        <img
                                          src={issueItem.coverUrl}
                                          alt="issue-img"
                                          className="series-image"
                                        />
                                      </div>
                                    </td>
                                    <td className="custom-th">
                                      <div className="default-color center">
                                        <Trans>Issue {issueItem.number}</Trans>
                                      </div>
                                    </td>
                                    <td className="custom-th">
                                      <DeliveryStatusComponent issue={issueItem} />
                                    </td>
                                    <td className="custom-th">
                                      <div className="default-color center">
                                        USD $
                                        {seriesItem.issues &&
                                          (seriesItem.price / seriesItem.issues.length).toFixed(2)}
                                      </div>
                                    </td>
                                    {issueItem.status === 'AVAILABLE' && !isTablet ? (
                                      <td className="custom-th">
                                        <Button
                                          as={Link}
                                          to={
                                            issueItem.orderId
                                              ? `/review-order/${seriesItem.id}/${issueItem.orderId}`
                                              : `/personalize/${issueItem.id}`
                                          }
                                          className="custom-button primary issue-personalize"
                                        >
                                          <Trans>Personalize</Trans>
                                        </Button>
                                      </td>
                                    ) : (
                                      <td className="custom-th" />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <td className="custom-th">
                                      <div className="default-color">
                                        <Trans>Issue</Trans> {issueItem.number}
                                      </div>
                                    </td>

                                    <td colSpan={4} className="custom-th">
                                      {lenghtActiveIssues && index === lenghtActiveIssues.length ? (
                                        <span className="italic-text">
                                          <Trans>Not available yet… soon!</Trans>
                                        </span>
                                      ) : (
                                        <span className="italic-text">
                                          <Trans>Not available yet…</Trans>
                                        </span>
                                      )}
                                    </td>
                                  </>
                                )}
                              </tr>
                              {issueItem.status === 'AVAILABLE' && isTablet && (
                                <div className="custom-th">
                                  <Button
                                    as={Link}
                                    to={
                                      issueItem.orderId
                                        ? `/review-order/${seriesItem.id}/${issueItem.orderId}`
                                        : `/personalize/${issueItem.id}`
                                    }
                                    className="custom-button primary issue-personalize"
                                  >
                                    <Trans>Personalize</Trans>
                                  </Button>
                                </div>
                              )}
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })
      ) : (
        <div className="empty">
          <img className="empty_image" src={require('../../images/empty.png')} alt="user-logo" />
          <p className="empty_title">
            <Trans>Such emptiness…</Trans>
          </p>
          <p className="empty_description">
            <Trans>You have no active subscriptions</Trans>
          </p>
        </div>
      )}
    </Accordion>
  );
};

export default DesctopSubscription;
