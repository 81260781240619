import {t} from "@lingui/macro";
import * as Yup from 'yup';

export const accountSchema = (i18n: any) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n._(`The minimum number of characters in this field is 2.`))
      .max(20, i18n._(`The maximum number of characters in this field is 20.`))
      .required(i18n._(`This field is required.`)),
    lastName: Yup.string()
      .min(2, i18n._(`The minimum number of characters in this field is 2.`))
      .max(20, i18n._(`The maximum number of characters in this field is 20.`))
      .required(i18n._(`This field is required.`)),
    shippingAddress: Yup.object({
      address1: Yup.string()
        .min(2, i18n._(`The minimum number of characters in this field is 2.`))
        .max(20, i18n._(`The maximum number of characters in this field is 20.`))
        .required(i18n._(`This field is required.`)),
      state: Yup.string()
        .required(i18n._(`This field is required.`))
        .max(40, i18n._(`The maximum number of characters in this field is 20.`)),
      town: Yup.string()
        .required(i18n._(`This field is required.`))
        .max(20, i18n._(`The maximum number of characters in this field is 20.`)),
      postcode: Yup.string()
        .required(i18n._(`This field is required.`))
        .max(20, i18n._(`The maximum number of characters in this field is 20.`)),
      country: Yup.string()
        .required(i18n._(`This field is required.`))
        .nullable(),
    }),
  });
};

export const registrationSchema = (i18n: any) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n._(`The minimum number of characters in this field is 2.`))
      .max(20, i18n._(`The maximum number of characters in this field is 20.`))
      .required(i18n._(`This field is required.`)),
    lastName: Yup.string()
      .min(2, i18n._(`The minimum number of characters in this field is 2.`))
      .max(20, i18n._(`The maximum number of characters in this field is 20.`))
      .required(i18n._(`This field is required.`)),
    email: Yup.string()
      .email(i18n._(t`Make sure, that email contains sign @ and valid symbols.`))
      .required(i18n._(`This field is required.`)),
    password: Yup.string().required(i18n._(`This field is required.`)),
  });
};

export const signinSchema = (i18n: any) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(i18n._(t`Make sure, that email contains sign @ and valid symbols.`))
      .required(i18n._(`This field is required.`)),
    password: Yup.string().required(i18n._(`This field is required.`)),
  });
};

export const orderSchema = (i18n: any) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n._(`The minimum number of characters in this field is 2.`))
      .max(20, i18n._(`The maximum number of characters in this field is 20.`))
      .required('This field is required.'),
    lastName: Yup.string()
      .min(2, i18n._(`The minimum number of characters in this field is 2.`))
      .max(20, i18n._(`The maximum number of characters in this field is 20.`))
      .required('This field is required.'),
    email: Yup.string()
      .email(i18n._(t`Make sure, that email contains sign @ and valid symbols.`))
      .required('This field is required.'),
    shippingAddress: Yup.object({
      address1: Yup.string()
        .min(2, i18n._(`The minimum number of characters in this field is 2.`))
        .max(20, i18n._(`The maximum number of characters in this field is 20.`))
        .required('This field is required.'),
      address2: Yup.string()
        .max(20, i18n._(`The maximum number of characters in this field is 20.`))
        .nullable(),
      town: Yup.string()
        .required('This field is required.')
        .max(20, i18n._(`The maximum number of characters in this field is 20.`)),
      state: Yup.string()
        .required('This field is required.')
        .max(20, i18n._(`The maximum number of characters in this field is 20.`)),
      postcode: Yup.string()
        .required('This field is required.')
        .max(20, i18n._(`The maximum number of characters in this field is 20.`)),
      country: Yup.string().required('This field is required.'),
    }),
  });
};
