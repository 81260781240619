import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Footer from '../../components/Footer';
import HomeHeader from '../../components/HomeHeader';
import LoginModal from '../../components/LoginModal';
import HomeRouter from '../../Routing/HomeRouter';
import './styles.scss';

export const LoginFormContext = React.createContext<any>(null);
export const ModalMenuContext = React.createContext<{
  modalMenuOpen: boolean;
  changeModalMenuOpen: any;
}>({
  modalMenuOpen: false,
  changeModalMenuOpen: () => {},
});

const Home: React.FC = () => {
  const [isVisibleLoginForm, setVisibleLoginForm] = useState<boolean>(false);
  const [modalMenuOpen, changeModalMenuOpen] = useState<boolean>(false);

  const onCloseLoginForm = useCallback(() => {
    setVisibleLoginForm(false);
  }, []);

  const value = useMemo(
    () => ({
      modalMenuOpen,
      changeModalMenuOpen,
    }),
    [modalMenuOpen, changeModalMenuOpen],
  );

  const onShowLoginForm = useCallback(() => {
    setVisibleLoginForm(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <LoginFormContext.Provider value={onShowLoginForm}>
        <ModalMenuContext.Provider value={value}>
          <HomeHeader />
          <div className={`content ${modalMenuOpen ? 'modal-menu-open-content' : ''}`}>
            <HomeRouter />
          </div>
          <Footer />
        </ModalMenuContext.Provider>
      </LoginFormContext.Provider>
      <LoginModal isVisible={isVisibleLoginForm} onClose={onCloseLoginForm} />
    </>
  );
};

export default Home;
