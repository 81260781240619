import { Trans } from '@lingui/macro';
import React from 'react';
// @ts-ignore
import { Row } from 'react-bootstrap';
import LoginComponent from '../Login';

interface LoginPropsTypes {
  onClose: () => void;
}
const Login: React.FC<LoginPropsTypes> = props => {
  return (
    <div className="unreg-login">
      <Row>
        <p className="unreg-login__title">
          <Trans>An account is required to complete your order.</Trans>
        </p>
        <p className="unreg-login__subtitle">
          <Trans>Please sign in or create an account.</Trans>
        </p>
      </Row>
      <LoginComponent isModal={true} />
    </div>
  );
};

export default Login;
