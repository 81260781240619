import React from 'react';
import { Elements } from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutForm';

// tslint:disable-next-line: one-variable-per-declaration
interface MyStoreCheckoutProps {
  orderId: string;
  seriesId: string;
}
const MyStoreCheckout: React.FC<MyStoreCheckoutProps> = props => {
  return (
    <Elements>
      <InjectedCheckoutForm orderId={props.orderId} seriesId={props.seriesId} />
    </Elements>
  );
};

export default MyStoreCheckout;
