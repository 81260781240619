import { RefObject, useCallback, useEffect } from 'react';
export const useClickOutside = (ref: RefObject<any>, handler: (e: any) => any) => {
  const handleClick = useCallback((event: any) => {
    if(ref.current && !ref.current.contains(event.target)) {
      handler(event);
    }
  }, [ref, handler]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    }
  }, [handleClick]);
};