import { Trans } from '@lingui/macro';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements';
import { ScreenWidthContext } from '../../App';
import { stripeElementStyleOptions } from '../../constants';
import { ReactComponent as LockIcon } from '../../images/lock.svg';
import './styles.scss';

interface CardSectionProps {
  createToken: (cardName: string) => void;
  isPending: boolean;
  orderId: string;
  seriesId: string;
}

const CardSection: React.FC<CardSectionProps> = props => {
  const { isPending, orderId, seriesId } = props;
  const history = useHistory();
  const { screenWidth } = useContext(ScreenWidthContext);
  const [cardName, setCardName] = useState<string>('');
  const isTablet = useMemo(() => screenWidth >= 426 && screenWidth <= 1024, [screenWidth]);

  const onBackButtonEvent = useCallback(
    (e: any) => {
      e.preventDefault();
      history.push(`/shipping-edit/${seriesId}/${orderId}`);
    },
    [history, seriesId, orderId],
  );

  useEffect(() => {
    window.onpopstate = onBackButtonEvent;
    return () => {
      window.onpopstate = null;
    };
  }, [onBackButtonEvent]);

  const sentForm = (ev: any) => {
    ev.preventDefault();
    props.createToken(cardName);
  };

  return (
    <form onSubmit={sentForm}>
      <div className="card_form">
        <div className="card-form-row-3">
          <div>
            <label className="card-form-label">
              <Trans>Card Number</Trans>
            </label>
            <CardNumberElement style={{ ...stripeElementStyleOptions }} />
          </div>
          <div>
            <label className="card-form-label">
              <Trans>Exp Date</Trans>
            </label>
            <CardExpiryElement style={{ ...stripeElementStyleOptions }} />
          </div>
          <div>
            <label className="card-form-label">
              <Trans>CVC</Trans>
            </label>
            <CardCvcElement style={{ ...stripeElementStyleOptions }} />
          </div>
        </div>
        {/*<div className="whatCvc">*/}
        {/*  <Link to="/" className="help-link">What's this?</Link>*/}
        {/*</div>*/}
        <div className="card_form-row">
          <div>
            <label className="card-form-label">
              <Trans>Name on card</Trans>
            </label>
            <input
              className="card-form-name"
              name={cardName}
              placeholder="Name on Card"
              onChange={e => setCardName(e.target.value)}
              type="text"
              required
            />
          </div>
        </div>
      </div>
      <div className="card-form-buttons">
        <Button
          className="custom-button gray"
          onClick={() => history.push(`/shipping-edit/${seriesId}/${orderId}`)}
        >
          <Trans>Back</Trans>
        </Button>
        {isTablet && (<div className="transactions-are">
          <LockIcon className="lock-icon" />{' '}
          <Trans>All transactions are secured with SSL encryption</Trans>
        </div>)}
        <Button type="submit" disabled={isPending} className="custom-button primary purchase">
          <Trans>Confirm Purchase</Trans>
        </Button>
      </div>
      {!isTablet && (<div className="transactions-are">
        <LockIcon className="lock-icon" />{' '}
        <Trans>All transactions are secured with SSL encryption</Trans>
      </div>)}
    </form>
  );
};

export default CardSection;
