import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Account from '../pages/Account';
import Confirmation from '../pages/Confirmation';
import ForgotPassword from '../pages/ForgotPassword';
import HomePage from '../pages/HomePage';
import Login from '../pages/Login';
import PasswordRecovery from '../pages/PasswordRecovery';
import PaymentPage from '../pages/PaymentPage';
import Personalize from '../pages/Personalize';
import ResetPassword from '../pages/ResetPassword';
import ReviewOrder from '../pages/ReviewOrder';
import SeriesDetails from '../pages/SeriesDetails';
import Shipping from '../pages/Shipping';
import ShippingEdit from '../pages/Shipping/ShippingEdit';

const HomeRouter: React.FC = () => (
  <Switch>
    <Route path="/" exact component={HomePage} />
    <Route path="/forgot" component={ForgotPassword} />
    <Route path="/reset" component={ResetPassword} />
    <Route path="/login" component={Login} />
    <Route path="/account" component={Account} />
    <PrivateRoute path="/payment/:seriesId/:orderId" component={PaymentPage} />
    <PrivateRoute path="/shipping/:seriesId/:issueId" component={Shipping} />
    <PrivateRoute path="/shipping-edit/:seriesId/:orderId" component={ShippingEdit} />
    <PrivateRoute path="/review-order/:seriesId/:orderId" component={ReviewOrder} />
    <PrivateRoute path="/confirmation/:seriesId/:orderId/:paymentId" component={Confirmation} />
    <PrivateRoute path="/confirmation/:seriesId/:orderId" component={Confirmation} />
    <Route path="/series/:seriesId" exact component={SeriesDetails} />
    {/*<Route path="/series/:seriesId/issue/:issueId" exact component={IssueDetails} />*/}
    {/*<PrivateRoute path="/basket" component={Basket} />*/}
    {/*<PrivateRoute path="/my_series" component={MySeries} />*/}
    <PrivateRoute path="/password_recovery" component={PasswordRecovery} />
    <Route path="/personalize/:issueId" component={Personalize} />
  </Switch>
);

export default HomeRouter;
