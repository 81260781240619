import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Button, Container, Form, Row } from 'react-bootstrap';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ScreenWidthContext } from '../../App';
import { I18nLanguageContext } from '../../components/I18nLoader';
import { userService } from '../../services/UserService';
import { ModalMenuContext } from '../Home';
import './styles.scss';

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const { screenWidth } = useContext(ScreenWidthContext);
  const { modalMenuOpen, changeModalMenuOpen } = useContext(ModalMenuContext);
  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const { i18n } = useContext(I18nLanguageContext);

  const shema = Yup.object().shape({
    email: Yup.string()
      .email(i18n._(t`Make sure, that email contains sign @ and valid symbols.`))
      .required(i18n._(t`This field is required.`)),
  });

  useEffect(() => {
    if (userService.isAuthenticated()) {
      history.push('/');
    }
  }, [history]);

  const sendForgotPassword = useCallback(
    async (values: any) => {
      try {
        await userService.forgotPassword(values.email);
        history.push('/');
        NotificationManager.success(i18n._(t`Verification email was sent.`), i18n._(t`Success`));
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error`));
      }
    },
    [history, i18n],
  );
  return (
    <div>
      <div className="red-header">
        <p className="header-title">
          <Trans>Reset password</Trans>
        </p>
      </div>
      <Container className="reset-password-container">
        <Row className="password-form">
          <Formik
            validationSchema={shema}
            initialValues={{ email: '' }}
            enableReinitialize
            onSubmit={sendForgotPassword}
          >
            {({ handleSubmit, handleChange, values, errors, handleBlur, touched }) => {
              return (
                <Form onSubmit={handleSubmit} className="form-body">
                  <Form.Group controlId="formBasicPassword" className="email-input">
                    <I18n>
                      {({ i18n }) => (
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder={i18n._(t`Email`)}
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          onBlur={handleBlur}
                        />
                      )}
                    </I18n>
                    <Form.Control.Feedback type="invalid">
                      {touched.email && errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="reset-email-button primary">
                    <Trans>Send reset instructions</Trans>
                  </Button>
                  <Row>
                    <Button
                      variant="primary"
                      className="back-button gray"
                      onClick={() =>
                        isMobile ? changeModalMenuOpen(!modalMenuOpen) : history.push('/login')
                      }
                    >
                      <Trans>Back</Trans>
                    </Button>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
