import { Trans } from '@lingui/macro';
import React, { useMemo, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import IssuePreview from '../IssuePreview';
import { ScreenWidthContext } from '../../../App';

import './styles.scss';

interface MobilePreviewProps {
  show: boolean;
  handleClose: () => void;
  issueTitle: string;
  seriesTitle: string;
  images: string[];
  loading: boolean;
  assets: any[];
  assetName: any;
  onSubmit: any;
}

const MobilePreview: React.FC<MobilePreviewProps> = props => {
  const {
    show,
    handleClose,
    issueTitle,
    seriesTitle,
    images,
    loading,
    assets,
    assetName,
    onSubmit,
  } = props;
  const { screenWidth } = useContext(ScreenWidthContext);

  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const isTablet = useMemo(() => screenWidth >= 426 && screenWidth <= 1024, [screenWidth]);
  return (
    <Modal show={show} onHide={handleClose} className="preview_modal">
      <Modal.Header closeButton>
        <Modal.Title className="preview_title">
          <Trans>Preview</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="preview_body">
        <div className="preview_body_series-title">{seriesTitle}</div>
        <div className="preview_body_issue-title">{issueTitle}</div>
        <div className="preview_body_preview">
          <IssuePreview
            images={images}
            loading={loading}
            assets={assets || []}
            assetName={assetName}
          />
        </div>
        {isMobile && (
          <Button
            variant="primary"
            className="personalize_form_buttons_order primary"
            onClick={() => onSubmit()}
          >
            <Trans>Order Issue</Trans>
          </Button>
        )}
        {isTablet && (
          <div className="preview-button-container">
            <Button
              variant="primary"
              className="personalize_form_buttons_preview gray"
              onClick={handleClose}
            >
              <Trans>Close</Trans>
            </Button>
            <Button
              variant="primary"
              className="personalize_form_buttons_order primary"
              onClick={() => onSubmit()}
            >
              <Trans>Order Issue</Trans>
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MobilePreview;
