import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { Formik } from 'formik';
import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { ScreenWidthContext } from '../../App';
import { I18nLanguageContext } from '../../components/I18nLoader';
import { userService } from '../../services/UserService';
import { ModalMenuContext } from '../Home';

const ResetPassword: React.FC = () => {
  const { screenWidth } = useContext(ScreenWidthContext);
  const { modalMenuOpen, changeModalMenuOpen } = useContext(ModalMenuContext);
  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const { i18n } = useContext(I18nLanguageContext);

  const shema = Yup.object({
    password: Yup.string().required(i18n._(t`This field is required.`)),
    passwordConfirmation: Yup.string()
      .required(i18n._(t`This field is required.`))
      .oneOf([Yup.ref('password'), null], i18n._(t`Passwords do not match.`)),
  });

  useEffect(() => {
    if (userService.isAuthenticated()) {
      userService.logout();
    }
  });
  const history = useHistory();
  const location = useLocation();

  const sendResetPassword = useCallback(
    async (values: any) => {
      const { token } = queryString.parse(location.search);
      try {
        await userService.resetPassword({ password: values.password, token });
        NotificationManager.success(
          i18n._(t`Your password successfuly changed!`),
          i18n._(t`Success!`),
        );
        history.push('/');
      } catch (e) {
        NotificationManager.error(e.message, i18n._(t`Error!`));
      }
    },
    [history, location.search, i18n],
  );

  return (
    <div>
      <div className="red-header">
        <p className="header-title">
          <Trans>Set new password</Trans>
        </p>
      </div>
      <Container className="reset-password-container set-new-password">
        <Row className="password-form">
          <Formik
            validationSchema={shema}
            initialValues={{ password: '', passwordConfirmation: '' }}
            enableReinitialize
            onSubmit={sendResetPassword}
          >
            {({ handleSubmit, handleChange, values, errors, touched, handleBlur }) => {
              return (
                <Form onSubmit={handleSubmit} noValidate className="form-body">
                  <Form.Row className="reset-password-row">
                    <Form.Group as={Col} controlId="formBasicPassword">
                      <I18n>
                        {({ i18n }) => (
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder={i18n._(t`Enter new password`)}
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                            onBlur={handleBlur}
                          />
                        )}
                      </I18n>
                      <Form.Control.Feedback type="invalid">
                        {touched.password && errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className="reset-password-row">
                    <Form.Group as={Col} controlId="formBasicPassword">
                      <I18n>
                        {({ i18n }) => (
                          <Form.Control
                            type="password"
                            name="passwordConfirmation"
                            placeholder={i18n._(t`Repeat password`)}
                            value={values.passwordConfirmation}
                            onChange={handleChange}
                            isInvalid={!!errors.passwordConfirmation}
                            onBlur={handleBlur}
                          />
                        )}
                      </I18n>
                      <Form.Control.Feedback type="invalid">
                        {touched.passwordConfirmation && errors.passwordConfirmation}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Button className="primary set-password-button" variant="primary" type="submit">
                    <Trans>Set new password</Trans>
                  </Button>
                  <Row>
                    <Button
                      variant="primary"
                      className="back-button gray"
                      onClick={() =>
                        isMobile ? changeModalMenuOpen(!modalMenuOpen) : history.push('/login')
                      }
                    >
                      <Trans>Back</Trans>
                    </Button>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
