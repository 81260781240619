import { Trans } from '@lingui/macro';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useLoads } from 'react-loads';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ScreenWidthContext } from '../../App';
import { I18nLanguageContext } from '../../components/I18nLoader';
import Loading from '../../components/Loading';
import { OrderReview, Series } from '../../declarations';
import feathersApp from '../../utils/feathers';
import moment from "moment";

import './styles.scss';

const Confirmation: React.FC<
  RouteComponentProps<{ orderId: string; seriesId: string; paymentId: string }>
> = props => {
  const { language } = useContext(I18nLanguageContext);
  const history = useHistory();
  const [series, setSeries] = useState<Series>();
  const [issue, setIssue] = useState();
  const [invoiceUrl, setInvoiceUrl] = useState();
  const [deliveryDate, setDeliveryDate] = useState();
  const [last4, setLast4] = useState();
  const { seriesId, orderId, paymentId } = props.match.params;
  const { screenWidth } = useContext(ScreenWidthContext);

  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const isTablet = useMemo(() => screenWidth >= 426 && screenWidth <= 1024, [screenWidth]);
  const formattedDeliveryDate = deliveryDate?.format(`${isMobile || isTablet ? 'MMM D YYYY' : 'D MMMM YYYY'}`);


  const [shipmentInfo, setShipmentInfo] = useState<OrderReview>({
    email: '',
    shippingAddress: {
      address1: '',
      state: '',
      country: '',
      postcode: '',
      town: '',
    },
  });

  const shippingPrice = 3.99;

  const onBackButtonEvent = useCallback(
    (e: any) => {
      e.preventDefault();
      history.push(`/series/${seriesId}`);
    },
    [history, seriesId],
  );

  useEffect(() => {
    window.onpopstate = onBackButtonEvent;
    return () => {
      window.onpopstate = null;
    };
  }, [onBackButtonEvent]);

  const getOrderInfo = useCallback(async () => {
    try {
      const order = await feathersApp.service('orders').find({
        query: {
          id: orderId,
          includeShipment: true,
          includeIssues: true,
        },
      });

      setDeliveryDate(moment(order.data[0].purchasedAt).add(7, 'days'));
      setIssue(order.data[0].issues[0]);
      const shippingAddress = order.data[0].order_shipment;
      const o = {
        email: shippingAddress.email,
        shippingAddress,
      };
      setShipmentInfo(o);
      if (order.data[0].issues[0] && order.data[0].issues[0].number <= 1) {
        const paymentInfo = await feathersApp.service('payments').get(parseInt(paymentId, 10));
        setLast4(paymentInfo.last4);
        setInvoiceUrl(paymentInfo.receiptUrl);
      }

      const seriesData = await feathersApp.service('series').get(parseInt(seriesId, 10));
      setSeries(seriesData);
    } catch (e) {
      console.log(e);
    }
  }, [orderId, paymentId, seriesId]);

  const { isPending } = useLoads(getOrderInfo, {}, []);

  return (
    <div className="confirmation-container">
      <div className="order-confirmation">
        <Row>
          <span className="confirmation-title">
            <Trans>Order Confirmation</Trans>
          </span>
          {issue && issue.number <= 1 && isTablet && !isMobile && (
            <Button
              variant="primary"
              className="custom-button gray invoice-button"
              onClick={() => {
                window.open(`${invoiceUrl}`);
              }}
            >
              <Trans>Invoice</Trans>
            </Button>
          )}
        </Row>
        <Row>
          <img className="confirman-image" src={require('../../images/confirman.png')} alt="s" />
        </Row>
        <Row className="sub-info">
          <p>
            <span>
              <Trans>BOOM!</Trans>
            </span>
            {issue && issue.number > 1
              ? ` Your ${issue.number} issue is on its way. You should have it withing 7 days.`
              : ` Your subscription has been accepted and your first issue
            is being processed. You should have it within 7 days.`}
          </p>
        </Row>
      </div>
      {isPending ? (
        <Loading />
      ) : (
        <div className="invoice">
          <Row className="invoice-row">
            {issue && issue.number <= 1 && !isTablet && (
              <Button
                variant="primary"
                className="custom-button gray invoice-button"
                onClick={() => {
                  window.open(`${invoiceUrl}`);
                }}
              >
                <Trans>Invoice</Trans>
              </Button>
            )}
          </Row>
          <Row>
            <div className="invoice-table">
              <div className="order-wrap">
                <span className="order-title">
                  <Trans>Order</Trans>
                </span>
                <div className="order-rows">
                  <Row>
                    <p className="order-rows__title">
                      {series && (series.title[language] || series.title.default)}
                      {issue && issue.number > 1
                        ? ` - ${issue && issue.number} Issue`
                        : ` (${series && series.issuesNumber} Issues)`}
                    </p>
                    {issue && issue.number <= 1 && <span>${(series && series.price) || 0}</span>}
                  </Row>
                  {issue && issue.number <= 1 && (
                    <Row>
                      <p>
                        <Trans>Shipping</Trans>
                      </p>
                      <span>${shippingPrice}</span>
                    </Row>
                  )}
                  {issue && issue.number <= 1 && (
                    <Row>
                      <p>
                        <Trans>Total</Trans>
                      </p>
                      <span>${series && (series.price + shippingPrice).toFixed(2)}</span>
                    </Row>
                  )}
                </div>
              </div>
              <div className="contacts-wrap">
                <Row>
                  <span>{isMobile ? 'Shipping' : 'Contact'}</span>
                  <p>{shipmentInfo.email}</p>
                </Row>
                <Row>
                  <span>
                    <Trans>Ship to</Trans>
                  </span>
                  <p>
                    {shipmentInfo.shippingAddress.address1}, {shipmentInfo.shippingAddress.state},{' '}
                    {shipmentInfo.shippingAddress.town}, {shipmentInfo.shippingAddress.postcode},{' '}
                    {shipmentInfo.shippingAddress.country}
                  </p>
                </Row>
                <Row>
                  <span>
                    <Trans>Method</Trans>
                  </span>
                  <p className="shipping-method">
                    <Trans>Standard Shipping</Trans>
                    <span className="shipping-date">
                      {isMobile ? (
                        <> <Trans>- Get it by </Trans>&nbsp;{formattedDeliveryDate} </>
                      ) : (
                        <> <Trans>(Get it by </Trans>&nbsp;{formattedDeliveryDate}) </>
                      )}
                    </span>
                  </p>
                </Row>
                <Row>
                  <span>
                    <Trans>Payment</Trans>
                  </span>
                  <p>
                    <Trans>Mastercard ending in</Trans>&nbsp;{last4}
                  </p>
                </Row>
              </div>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Confirmation;
