import { Trans } from '@lingui/macro';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import { ScreenWidthContext } from '../../App';

import {
  advantagesOptions,
  attackOptions,
  avatarOptions,
  featuresOptions,
  languageOptions,
} from './options';

import './styles.scss';

interface QuestionOption {
  id: number;
  value: string;
  url?: string;
  title: any;
}

interface AttackOption {
  id: number;
  value: string;
  fileName: string;
  background: string;
  title: any;
}

interface FeatureOption {
  id: number;
  title: object;
  description: object;
  url: string;
  mobileUrl: string;
  className?: string;
}

const HomePage: React.FC<QuestionOption> = () => {
  const history = useHistory();
  const [avatar, setAvatar] = useState<QuestionOption>(avatarOptions[0]);
  const [language, setLanguage] = useState(languageOptions[0]);
  const [attack, setAttack] = useState(attackOptions[0]);
  const [isImgLoading, setImageLoading] = useState(false);
  const [isPreviewLoading, setPreviewLoading] = useState(false);
  const [isBackgroundLoading, setBackgroundLoading] = useState(false);
  const [previewBackground, setPreviewBackground] = useState(attackOptions[0].background);
  const { screenWidth } = useContext(ScreenWidthContext);
  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const attackName = attackOptions[0].fileName;
  const [previewUrl, setPreviewUrl] = useState([
    `/images/${languageOptions[0].value}_${avatarOptions[0].value}.png`,
    require(`../../images/${attackName}`),
  ]);
  const previewImg = new Image();
  const backgroundPreviewImg = new Image();

  useEffect(() => {
    const img = new Image();

    setImageLoading(true);
    img.onload = () => {
      setImageLoading(false);
    };
    img.src = require(`../../images/${isMobile ? 'mobile-' : ''}top-banner-home.jpg`);
  }, [isMobile]);

  useMemo(() => {
    setPreviewLoading(true);
    setBackgroundLoading(true);

    backgroundPreviewImg.onload = () => {
      setBackgroundLoading(false);
    };

    previewImg.onload = () => {
      setPreviewLoading(false);
    };

    previewImg.src = require(`../../images/${attack.fileName}`);
    backgroundPreviewImg.src = require(`../../images/${
      isMobile ? 'mobile-' : ''
    }scenarios-background-${previewBackground}.jpg`);

    if (isMobile) {
      setPreviewUrl(['', previewImg.src]);
    } else {
      setPreviewUrl([`/images/${language.value}_${avatar.value}.png`, previewImg.src]);
    }
  }, [
    language,
    attack,
    avatar,
    isMobile,
    backgroundPreviewImg.onload,
    backgroundPreviewImg.src,
    previewBackground,
    previewImg.onload,
    previewImg.src,
  ]);

  return isImgLoading ? (
    <Loading />
  ) : (
    <div className="home-container">
      <div className={`${isMobile ? 'mobile-' : ''}top-banner`}>
        <h1>
          <Trans>Take control of your own adventure.</Trans>
        </h1>
        <p>
          {isMobile ? (
            <Trans>
              Take control of the story, make the important decisions and help save the day.
            </Trans>
          ) : (
            <Trans>
              Now you can take control of the story, make the important decisions and help your hero
              defeat the villian and help save the day.
            </Trans>
          )}
        </p>
        <Button className="custom-button home-button" onClick={() => history.push('/series/59')}>
          <Trans>Let's Begin!</Trans>
        </Button>
      </div>
      {isMobile ? (
        <Carousel className="features-mobile" interval={1500} controls={false}>
          {featuresOptions.map((featureOption: FeatureOption) => (
            <div className="feature-card" key={featureOption.id}>
              <div className="feature-card_image">
                <img src={`${featureOption.mobileUrl}`} alt="feature" />
              </div>
              <div className="feature-card_info">
                <p className="feature-card_info_title">{featureOption.title}</p>
                <p className="feature-card_info_description">{featureOption.description}</p>
              </div>
            </div>
          ))}
        </Carousel>
      ) : (
        <div className="features">
          {featuresOptions.map((featureOption: FeatureOption) => (
            <div className="feature-card" key={featureOption.id}>
              <div className="feature-card_image">
                <img src={`${featureOption.url}`} alt="feature" />
              </div>
              <div className="feature-card_info">
                <p className="feature-card_info_title">{featureOption.title}</p>
                <p className="feature-card_info_description">{featureOption.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="scenarios">
        <div className="scenarios__left-column">
          <div className="scenarios__info">
            <p className="scenarios__info_title">
              <Trans>Give it a try!</Trans>
            </p>
            <p className="scenarios__info_subtitle">
              <Trans>
                Rollover and make your own choices from the options provided and watch how they
                update the comic!
              </Trans>
            </p>
            {!isMobile && (
              <div className="scenarios__info_avatars-wrap">
                <p className="scenarios__info_select-title">Select your avatar</p>
                <div className="scenarios__info_avatars-list">
                  {avatarOptions.map((avatarOption: QuestionOption) => (
                    <div
                      key={avatarOption.id}
                      onClick={() => setAvatar(avatarOption)}
                      className={`${avatar.id === avatarOption.id ? 'selected-avatar' : ''} avatar`}
                    >
                      <img src={`${avatarOption.url}`} alt="avatar" />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="scenarios__info_scenarios-container">
              <div className="scenarios__info_language-wrap">
                <p className="scenarios__info_select-title">
                  <Trans>Select your language</Trans>
                </p>
                <div className="scenarios__info_language-list">
                  {languageOptions.map((languageOption: QuestionOption) => (
                    <div key={languageOption.id} className="variant">
                      <div
                        onClick={() => setLanguage(languageOption)}
                        className={`${
                          language.id === languageOption.id ? 'selected-text' : ''
                        } dotted`}
                      >
                        {languageOption.title}
                      </div>
                      <div className="point" />
                    </div>
                  ))}
                </div>
              </div>
              <div className="scenarios__info_attack-type-wrap">
                <p className="scenarios__info_select-title">
                  <Trans>Select your attack</Trans>
                </p>
                <div className="scenarios__info_attack-type-list">
                  {attackOptions.map((attackType: AttackOption) => (
                    <div key={attackType.id} className="variant">
                      <div
                        onClick={() => {
                          setAttack(attackType);
                          setPreviewBackground(attackType.background);
                        }}
                        className={`${attack.id === attackType.id ? 'selected-text' : ''} dotted`}
                      >
                        {attackType.title}
                      </div>
                      <div className="point" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {(isPreviewLoading || isBackgroundLoading) && isMobile ? (
          <Loading />
        ) : (
          <div className={`scenarios__right-column ${attack.value}`}>
            <div className="scenarios__right-column_preview">
              <div className="scenarios__right-column_left-page">
                <img alt="home-image" src={`${previewUrl[0]}`} />
              </div>
              <div className="scenarios__right-column_right-page">
                <img alt="home-image" src={`${previewUrl[1]}`} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="advantages-wrap">
        <div className="advantages">
          {advantagesOptions.map((advantage: FeatureOption) => (
            <div key={advantage.id} className={advantage.className}>
              <img src={isMobile ? advantage.mobileUrl : advantage.url} />
              <div className="info">
                <p className="advantages__title">{advantage.title}</p>
                <p className="advantages__description">{advantage.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="middle-banner">
        <img
          src={require(`../../images/${isMobile ? 'mobile-sm-logo.png' : 'sm-logo.png'}`)}
          alt="sm-logo"
          className="middle-banner_sm-logo"
        />
        <div className="middle-banner_info">
          <p>
            <Trans>
              The first series of Spider-man is now available. Join him as he takes on the Spider
              Slayer in Issue 1 today!
            </Trans>
          </p>
          {!isMobile && (
            <Button
              className="custom-button home-button"
              onClick={() => history.push('/series/59')}
            >
              <Trans>Let's Begin!</Trans>
            </Button>
          )}
        </div>
        {!isMobile && (
          <img
            src={require('../../images/new.png')}
            alt="logo"
            className="middle-banner_new-image"
          />
        )}
        {!isMobile && (
          <img
            src={require('../../images/spiderman.png')}
            alt="logo"
            className="middle-banner_right-image"
          />
        )}
      </div>
      <div className="dotted-bottom-banner">
        <p>
          <Trans>Ready to begin your new adventure?</Trans>
        </p>
        <Button className="primary" onClick={() => history.push('/series/59')}>
          <Trans>Let's Get Started</Trans>
        </Button>
      </div>
      {/*<div className="email-subscribe-wrap">*/}
      {/*  <div className="email-subscribe">*/}
      {/*    <p className="email-subscribe__title">Stay up to date with latest news and releases</p>*/}
      {/*    <Form.Control*/}
      {/*        type="email"*/}
      {/*        className="email-subscribe__input"*/}
      {/*        placeholder="Your email"*/}
      {/*    />*/}
      {/*    <Button className="email-subscribe__button gray"><Trans>Subscribe</Trans></Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default HomePage;
