export const VARIABLE_TYPES = {
  text: 'TEXT',
  selelct: 'SELECT',
};

export const OPTION_TYPES = {
  text: 'TEXT',
  img: 'IMAGE',
};

export const stripeElementStyleOptions = {
  // font: {
  //   family: 'SourceSansPro',
  //   src: "url('https://cuddlecompanions.org/wp-content/themes/diamondphoenix/fonts/effra.eot')"
  // },
  base: {
    fontSize: "18px",
    lineHeight: '1.56',
    // fontFamily: 'SourceSansPro',
    '::placeholder': {
      color: '#e3eaf9'
    }
  },

};
