import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.scss';

const Loading: React.FC = () => {
  return (
    <div className="loading-container">
      <Spinner animation="border" variant="secondary" />
    </div>
  );
};

export default Loading;
