import React, { useContext, useMemo } from 'react';
import { ScreenWidthContext } from '../../App';

interface PageProps {
  clickPage: () => void;
  isEmpty: boolean;
  src: string;
  assetsPage: any[];
  assetName: string;
}

const heightPage = 300;
const widthPage = 200;
const comicHeight = 260;
const comicWidth = 170;
const fontSize = 3.5277777778;
const Page: React.FC<PageProps> = props => {
  const { clickPage, isEmpty, src, assetsPage, assetName } = props;
  const { screenWidth } = useContext(ScreenWidthContext);
  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  return (
    <>
      {isEmpty && <div className="page-container empty-page" />}
      <div className="page-container" onClick={clickPage}>
        <img alt="page" className="image-page" src={src} draggable={false} />
        {assetsPage &&
          assetsPage.length &&
          assetsPage.map(asset => {
            return (
              <div
                key={asset.id}
                style={{
                  top: (((heightPage * asset.offsetTop) / 100 - 25) / comicHeight) * 100 + '%',
                  left: (((widthPage * asset.offsetLeft) / 100 - 15) / comicWidth) * 100 + '%',
                  width:
                    isMobile && assetName.length === 12
                      ? (asset.width * widthPage) / comicWidth + 2.5 + '%'
                      : (asset.width * widthPage) / comicWidth + '%',
                  height: (asset.height * heightPage) / comicHeight + '%',
                }}
                className="asset-position"
              >
                {assetName}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Page;
