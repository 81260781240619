import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLoads } from 'react-loads';
import { RouteComponentProps } from 'react-router-dom';
import { ScreenWidthContext } from '../../App';
import { I18nLanguageContext } from '../../components/I18nLoader';
import LoginModal from '../../components/LoginModal';
import { OPTION_TYPES, VARIABLE_TYPES } from '../../constants';
import { Issue, Question, Series } from '../../declarations';
import { userService } from '../../services/UserService';
import feathersApp from '../../utils/feathers';
import IssuePreview from './IssuePreview';
import QuestionBlock from './QustionsBlock';
import './styles.scss';

interface PersonalizeRouteParams {
  issueId: string;
}

const Personalize: React.FC<RouteComponentProps<PersonalizeRouteParams>> = props => {
  const { history } = props;
  const { issueId } = props.match.params;

  const [issue, setIssue] = useState<Issue>();
  const [series, setSeries] = useState<Series>();
  const [loadingPreview, setLoadingPreview] = useState();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionFields, setQuestionFields] = useState<any>({});
  const [isVisibleUnregForm, setVisibleUnregForm] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<any>();
  const [assets, setAssets] = useState<any[]>();
  const { screenWidth } = useContext(ScreenWidthContext);

  const isMobile = useMemo(() => screenWidth <= 425, [screenWidth]);
  const isTablet = useMemo(() => screenWidth >= 426 && window.innerWidth <= 1024, [screenWidth]);
  const onCloseUnregForm = useCallback(() => {
    setVisibleUnregForm(false);
  }, []);

  const showUnregForm = useCallback(() => {
    setVisibleUnregForm(true);
  }, []);

  const loadNameVariable = useCallback(async () => {
    const allVariables = await feathersApp.service('variables').find({
      query: {
        allForOrder: true,
        issuesIds: [issueId],
      },
    });
    const textVariable = allVariables.find((variable: any) => variable.type === 'TEXT');
    const userId = userService.userId;
    if (textVariable.id && userId) {
      const textAnswer = await feathersApp.service('choice').find({
        query: {
          variableId: textVariable.id,
          userId,
        },
      });
      if (textAnswer.data[0]) {
        setAssetName(textAnswer.data[0].text);
      }
    }
  }, [issueId]);

  const loadNewPreview = useCallback(
    async (values: any) => {
      setLoadingPreview(true);
      const { language: languageId, ...answers } = values;
      const optionsIds = Object.values(answers).map((o: any) => o.value);
      const questionName = Object.values(answers).find(optionId => typeof optionId === 'string');
      if (!questionName) {
        loadNameVariable();
      }
      setAssetName(questionName);
      const options = optionsIds.filter(optionId => typeof optionId === 'number');
      const { data: preview } = await feathersApp.service('generated-content').find({
        query: {
          answers: {
            languageId: languageId.value,
            optionsIds: options,
          },
          issueId,
        },
      });
      const assetsForPreview = await feathersApp.service('assets').find({
        query: {
          forPreview: true,
          issueId,
        },
      });
      setAssets(assetsForPreview);
      if (preview[0]) {
        setPreviewImages(preview[0].preview);
        setLoadingPreview(false);
      }
    },
    [issueId, loadNameVariable],
  );

  const parseUrlParams = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlEmail = urlParams.get('email');
    if (urlEmail) {
      const userEmail = userService.userEmail;
      const codeEmail = encodeURIComponent(urlEmail);
      if (urlEmail !== userEmail) {
        userService.logout();
        history.push(`/login?email=${codeEmail}&redirect_to=/personalize/${issueId}`);
      } else {
        history.push(`/personalize/${issueId}`);
      }
    }
  }, [history, issueId]);

  useEffect(() => {
    parseUrlParams();
    if (!questions || !series || !series.languages) {
      return;
    }
    let object = { language: { value: series.languages[0].id, label: series.languages[0].name } };
    questions.forEach((question: Question) => {
      if (question.type === VARIABLE_TYPES.selelct) {
        if (question.optionsType === OPTION_TYPES.img) {
          object = {
            ...object,
            [question.id]: {
              value: question.options[0].id,
              label: question.options[0].imageUrl,
            },
          };
        } else {
          object = {
            ...object,
            [question.id]: {
              value: question.options[0].id,
              label: question.options[0].name.default,
            },
          };
        }
      } else {
        object = { ...object, [question.id]: '' };
      }
    });
    setQuestionFields(object);
    loadNewPreview(object);
  }, [questions, series, loadNewPreview, parseUrlParams]);

  const loadQuestions = useCallback(async () => {
    const data = await feathersApp.service('variables').find({
      query: {
        allForOrder: true,
        notChosen: true,
        includeOptions: true,
        issuesIds: [issueId],
      },
    });
    const sortedQuestions = data.sort((a: any) => {
      if (a.type === 'TEXT') {
        return -1;
      } else {
        return 0;
      }
    });
    setQuestions(sortedQuestions);
  }, [issueId]);

  const loadSeries = useCallback(async () => {
    if (!issue || !issue.id) {
      return;
    }
    const data = await feathersApp.service('series').get(issue.seriesId, {
      query: {
        includeLanguages: true,
        includeIssues: true,
      },
    });
    if (userService.isAuthenticated()) {
      const order = await feathersApp.service('orders').find({
        query: {
          issueId,
        },
      });
      if (order.data[0] && order.data[0].id) {
        history.push('/account/subscription');
      } else {
        history.push(`/personalize/${issueId}`);
      }
    }

    setSeries(data);
  }, [issue, issueId, history]);

  const loadIssue = useCallback(async () => {
    const data = await feathersApp.service('issues').get(issueId);
    setIssue(data);
  }, [issueId]);

  const { isPending: isueLoading } = useLoads(loadIssue, {}, [issueId]);
  const { isPending: questionsLoading } = useLoads(loadQuestions, {}, [issueId]);
  const { isPending: seriesLoading } = useLoads(loadSeries, {}, [issue]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);

  const isLoading = isueLoading || questionsLoading || seriesLoading;
  const { language } = useContext(I18nLanguageContext);

  return (
    <div className="personalize">
      {isVisibleUnregForm && (
        <LoginModal isVisible={isVisibleUnregForm} onClose={onCloseUnregForm} />
      )}
      <QuestionBlock
        questionFields={questionFields}
        issue={issue}
        series={series}
        issueId={issueId}
        isLoading={isLoading}
        questions={questions}
        showUnregForm={showUnregForm}
        loadNewPreview={loadNewPreview}
        previewImages={previewImages}
        loadingPreview={loadingPreview}
        assets={assets || []}
        assetName={assetName}
      />

      {issue && series && !isMobile && !isTablet && (
        <IssuePreview
          issueTitle={issue.title[language] || issue.title.default}
          seriesTitle={series.title[language] || series.title.default}
          images={previewImages}
          loading={loadingPreview}
          assets={assets || []}
          assetName={assetName}
        />
      )}
    </div>
  );
};

export default Personalize;
