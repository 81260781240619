import React from 'react';
import LoginComponent from '../../components/Login';
import './styles.scss';

const Login: React.SFC<{}> = () => {
  return (
    <div className="login-page">
      <LoginComponent isModal={false} />
    </div>
  );
};

export default Login;
